import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
    Pagination
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import swal from "sweetalert";
import Loader from "../../layouts/nav/Loader.js";
import CommonPrint from "./CommonPrint.js";
import WorkflowHeader from "./WorkflowHeader.js";
const schema = yup.object().shape({
    first_name: yup.mixed(),
    last_name: yup.mixed(),
    address: yup.mixed(),
    city: yup.mixed(),
    state: yup.mixed(),
    AL: yup.mixed(),
    mobile_phone: yup.mixed(),
    elect_contractor: yup.string().nullable().required('Electrical contractor assignment is required'),
    elect_assigned_date: yup.string().nullable().required('Electrical Assigned date is required'),
    elect_cost: yup.string().nullable().required('Electrical Cost is required'),
    plumb_contractor: yup.string().nullable().required('Plumbing contractor assignment is required'),
    plumb_assigned_date: yup.string().nullable().required('Plumbing Assigned date is required'),
    plumb_cost: yup.string().nullable().required('Plumbing Cost is required'),
    elect_document: yup.mixed(),
    elect_document: yup.mixed(),
    comments: yup.string().nullable(),
    status: yup.string()
});
export default function ElectricalPlumbing({ activeTab }) {
    const [btntitle, setbtntitle] = useState("Add Electrical & Plumbing");
    const [cardtitle, setcardtitle] = useState("List of Electrical & Plumbing");
    const [Showform, setShowform] = useState(false);
    const [electricalplumbingtable, setelectricalplumbingtable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [activeDetails, setactiveDetails] = useState({});
    const [usertable, setusertable] = useState([]);
    const [memberId, setMemberId] = useState("");
    const [applicationTab, setApplicationTab] = useState("Pending");
    const [ShowApprove, setShowApprove] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    const { register: registerForm1, handleSubmit: handleSubmitForm1, formState: { errors1 }, reset: reset1, setValue: setValue1, getValues: getValues1 } = useForm({
        resolver: yupResolver(schema),
    });
    function checkboxFun(option) {
        console.log(option)
    }
    useEffect(() => {
        if (activeTab == "electricalplumbing") {
            setbtntitle("Add Electrical & Plumbing");
            setcardtitle("List of Electrical & Plumbing")
            setactiveuser({})
            setShowform(false)
            getWFElecAndPlumList('Pending');
            getMembers()
            setApplicationTab('Pending')
        }

    }, [activeTab])

    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
            user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchInput.toLowerCase()) 
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if(typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
            user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchInput.toLowerCase()) 
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if(page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
           currentPage = currentPage - 1;
           if(currentPage < 1) {
              currentPage = 1
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
           currentPage = currentPage + 1;
           if(currentPage > pages) {
              currentPage = pages;
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
           currentPage = 1
        } else if(page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
           currentPage = pages;
        } else {
           currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for(let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
           if(i <= pages)
           pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize) ;
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for(let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
           usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)
        let updatedState = {
           usersToBeShown: usersToBeShown,
           pageArray: pageArray,
           firstPage: firstPage,
           lastPage: lastPage,
           currentPage: currentPage,
           sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize))+parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
     }

     const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
           calculatePaginationDetails(txt !== undefined ? txt : e.target.text, electricalplumbingtable, currentPage);
        // }
     }

     const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
       // calculatePaginationDetails(1, electricalplumbingtable, currentPage)
        setTimeout(() => {
            if(document.querySelector(".page-link"))
            document.querySelector(".page-link").click()
        }, 1000);
     }

     useEffect(() => {
        calculatePaginationDetails(1, electricalplumbingtable, currentPage)
     }, [searchInput, electricalplumbingtable]);

    const getMembers = () => {
        apiServices.getContractorList().then(response => {
            setusertable(response.data)
        }).catch(error => {

        })
    }
    const getWFElecAndPlumList = (sType) => {
        setIsLoading(true);
        apiServices.getWFElecAndPlumList(sType).then(response => {
            setIsLoading(false);
            setelectricalplumbingtable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)
        }).catch(error => {
            setIsLoading(false);
        })
    }
    const onSubmit = (e) => {
        setIsLoading(true);
        apiServices.updateWFElecAndPlum(getValues(), memberId).then(response => {
            // reset()
            setIsLoading(false);
            notifySuccess(response.data[0].message);
            cardbtn()
            getWFElecAndPlumList('Pending');
        }).catch(error => {
            setIsLoading(false);
        })

    };
    const updateWFStatus = (sType) => {
        var message;
        if (sType == "Approved") {
            message = "Approve";
        } else {
            message = "Reject";
        }
        swal({
            title: message,
            text:
                "Are you sure you want to " + message + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setIsLoading(true);
                apiServices.updateWFStatus(sType, memberId, 'elect_plumb').then(response => {
                    setIsLoading(false);
                    notifySuccess(response.data[0].message);
                    cardbtn()
                    getWFElecAndPlumList('Pending');
                }).catch(error => {
                    setIsLoading(false);
                });
            } else {
                //   swal("Your imaginary file is safe!");
            }
        })


    };
    function GetFileName(file_id){
        if(typeof file_id === "string") {

            const parts = file_id?.split('/');
            return parts[parts.length - 1];
        }
    }
    function downloadDocument(e, file_id) {
        e.preventDefault()
        apiServices.DownloadDocument(file_id).then(response => {
            const parts = file_id.split('/');

            const lastPart = parts[parts.length - 1];
            //const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = lastPart// encodeURIComponent(lastPart); // Encode the file name
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Clean up the URL

        }).catch(error => {

        })
    }
    function cardbtn() {
        if (btntitle === 'Add Electrical & Plumbing' || btntitle === 'Edit Electrical & Plumbing' || btntitle === 'View Electrical & Plumbing') {
            setbtntitle("Back");
            setcardtitle("Add Electrical & Plumbing")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Electrical & Plumbing");
            setcardtitle("List of Electrical & Plumbing")
            setactiveuser({})
            setShowform(false)
        }
    }

    function Print(elem) {
        var mywindow = window.open('Iwashwell', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');

        mywindow.document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="./css/style.css">';

        mywindow.document.write(document.getElementById('printableElectrical').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }

    function viewuser(e, user) {
        e.preventDefault()
        setShowApprove(true);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('elect_contractor', user.elect_contractor_id);
            setValue('elect_assigned_date', user.elect_assigned_date);
            setValue('comments', user.comments);
            setValue('elect_cost', user.elect_cost);
            setValue('plumb_cost', user.plumb_cost);
            setValue('plumb_contractor', user.plumb_contractor_id);
            setValue('plumb_assigned_date', user.plumb_assigned_date);
            setactiveuser(getValues())
            setValue1('first_name', response.data[0].first_name);
            setValue1('last_name', response.data[0].last_name);
            setValue1('address', response.data[0].address);
            setValue1('mobile_phone', response.data[0].mobile_phone);
            setValue1('state', response.data[0].state);
            setValue1('city', response.data[0].city);
            setValue1('AL', response.data[0].AL);
            setValue1('elect_contractor', user.elect_contractor_id);
            setValue1('elect_assigned_date', user.elect_assigned_date);
            setValue1('comments', user.comments);
            setValue1('elect_cost', user.elect_cost);
            setValue1('plumb_cost', user.plumb_cost);
            setValue1('plumb_contractor', user.plumb_contractor_id);
            setValue1('plumb_assigned_date', user.plumb_assigned_date);
            var ts=getValues();
            ts.plumb_document=user.plumb_document;
            ts.elect_document=user.elect_document;
            setactiveuser(ts)
            if (user.elect_document != '')
                setShowApprove(false);
            // for (const key in response) {
            //     //setValue(key, value[key]);s
            // }
        }).catch(error => {

        })
        setMemberId(user.member_id)
        setbtntitle("Back");
        setcardtitle("View Electrical & Plumbing")
        setactiveuser(user)
        setactiveDetails(user)
        setShowform(true)
    }

    function edituser(e, user) {
        e.preventDefault()
        setMemberId(user.member_id)
        setShowApprove(true);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('elect_contractor', user.elect_contractor_id);
            setValue('elect_assigned_date', user.elect_assigned_date);
            setValue('comments', user.comments);
            setValue('elect_cost', user.elect_cost);
            setValue('plumb_cost', user.plumb_cost);
            setValue('plumb_contractor', user.plumb_contractor_id);
            setValue('plumb_assigned_date', user.plumb_assigned_date);
            var ts=getValues();
            ts.plumb_document=user.plumb_document;
            ts.elect_document=user.elect_document;
            setactiveuser(ts)
            if (user.elect_document != '')
                setShowApprove(false);
            // for (const key in response) {
            //     //setValue(key, value[key]);
            // }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("Edit Electrical & Plumbing")
        setactiveuser(user)
        setactiveDetails(user)
        setShowform(true)
    }

    function deleteuser(e, user) {
        e.preventDefault()
        swal({
            title: "Are you sure you want to delete?",
            text:
                "Once Deleted, it will be moved back to Installation stage",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {

                apiServices.deleteWFElecAndPlum(user.id).then(response => {
                    cardbtn()
                    getWFElecAndPlumList('Pending');
                    notifyError();
                    setbtntitle("Add Electrical & Plumbing");
                    setcardtitle("List of Electrical & Plumbing")
                    setactiveuser({})
                    setShowform(false)
                }).catch(error => {
                });
            } else {
                //   swal("Your imaginary file is safe!");
            }
        })

        //setactiveuser(user)

    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyError = () => {
        toast.error("Electrical & Plumbing Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <div>
                <div style={{ display: 'none' }} className="pdf-page" id="printableElectrical">
                    <div className="basic-form">

                        {/* <InvoicePrint></InvoicePrint> */}

                        <CommonPrint></CommonPrint>
                        <p>Electrical & Plumbing</p>
                        <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: 'bold', width: '200PX' }}>First Name:</td>
                                    <td>{activeuser.first_name}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Last Name:</td>
                                    <td>{activeuser.last_name}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Address:</td>
                                    <td>{activeuser.address}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Mobile Number:</td>
                                    <td>{activeuser.mobile_phone}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>State:</td>
                                    <td>{activeuser.state}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>City:</td>
                                    <td>{activeuser.city}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>ZipCode:</td>
                                    <td>{activeuser.AL}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Plumbing Contractor:</td>
                                    <td>{activeuser.plumb_contractor}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                    <td>{activeuser.plumb_assigned_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Plumbing Cost:</td>
                                    <td>{activeuser.plumb_cost}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Upload Documents:</td>
                                    <td>{activeDetails.plumb_document}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Electrical Contractor:</td>
                                    <td>{activeuser.elect_contractor}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                    <td>{activeuser.elect_assigned_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Electrical Cost:</td>
                                    <td>{activeuser.elect_cost}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Upload Documents:</td>
                                    <td>{activeDetails.elect_document}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                    <td>{activeuser.comments}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading ?
                <Loader />
                :
                <>
                    <Tab.Container defaultActiveKey="Pending" activeKey={applicationTab}>
                        <Nav as="ul" className="nav-tabs">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Pending" disabled={applicationTab == 'Pending' ? true : false} onClick={() => { getWFElecAndPlumList('Pending'); setApplicationTab('Pending') ;SetSearchInput("");}}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    Pending
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Approved" disabled={applicationTab == 'Approved' ? true : false} onClick={() => { getWFElecAndPlumList('Approved'); setApplicationTab('Approved');SetSearchInput(""); }}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    Completed
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-4">
                            <Tab.Pane eventKey="Pending">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <h4 className="card-title">{cardtitle}</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-end">
                                        {/* <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                    {btntitle}
                </button> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {Showform === true
                                        ?
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <WorkflowHeader activeMember={activeuser.member_id}></WorkflowHeader>
                                            <div className="row">
                                                {/* <div className="form-group mb-3 col-md-4">
                                                    <label>First Name</label>
                                                    <input
                                                        {...register("first_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        disabled={true}
                                                        value={activeuser['FIRST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Last Name</label>
                                                    <input
                                                        {...register("last_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        disabled={true}
                                                        value={activeuser['LAST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Address</label>
                                                    <input
                                                        {...register("address")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        disabled={true}
                                                        value={activeuser['ADDRESS']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Phone</label>
                                                    <input
                                                        {...register("mobile_phone")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        disabled={true}
                                                        value={activeuser['PHONE NO']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>State</label>
                                                    <input
                                                        {...register("state")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="State"
                                                        disabled={true}
                                                        value={activeuser['STATE']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>City</label>
                                                    <input
                                                        {...register("city")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="City"
                                                        disabled={true}
                                                        value={activeuser['CITY']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Zipcode</label>
                                                    <input
                                                        {...register("AL")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Zipcode"
                                                        disabled={true}
                                                        value={activeuser['ZIP CODE']}
                                                    />
                                                </div> */}
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Plumbing Contractor</label>
                                                    <select
                                                        {...register("plumb_contractor")}
                                                        // defaultValue={"option"}
                                                        id="SponsorinputState"
                                                        className="form-control"
                                                        //value={activeuser['PROFESSIONAL NAME'] || "Lucy McKenzie"}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                    >
                                                        {usertable.map((item, i) => {
                                                            if (item.scope == 'Plumbing') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                    </select>
                                                    {errors.plumb_contractor && <small id="emailHelp" class="text-danger">{errors.plumb_contractor?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned Date</label>
                                                    <input
                                                        {...register("plumb_assigned_date")}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Assign Date"
                                                        min={currentDate}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['ASSIGNED DATE']}
                                                    />
                                                    {errors.plumb_assigned_date && <small id="emailHelp" class="text-danger">{errors.plumb_assigned_date?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Plumbing Cost</label>
                                                    <input
                                                        {...register("plumb_cost")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="cost"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COST']}
                                                    />
                                                    {errors.plumb_cost && <small id="emailHelp" class="text-danger">{errors.plumb_costcost?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Upload Documents</label>
                                                    <input {...register("plumb_document")} type="file" className="makebtnleft" disabled={cardtitle === "View Electrical & Plumbing" ? true : false} />
                                                </div>
                                                {/* <div className="form-group mb-3 col-md-4">
                                                    <Link
                                                        onClick={(e) => downloadDocument(e,activeDetails.plumb_document)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </div> */}
                                                {activeuser.plumb_document && <div className="form-group mb-3 col-md-4">
                                                    {/* <Link
                                                        onClick={(e) => downloadDocument(e,activeuser.document)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i> {activeuser.document}
                                                    </Link> */}
                                                    <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.plumb_document)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.plumb_document)}</a>

                                                </div>}
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Electrical Contractor</label>
                                                    <select
                                                        {...register("elect_contractor")}
                                                        // defaultValue={"option"}
                                                        id="SponsorinputState"
                                                        className="form-control"
                                                        //value={activeuser['PROFESSIONAL NAME'] || "Lucy McKenzie"}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                    >
                                                        {usertable.map((item, i) => {
                                                            if (item.scope == 'Electrical') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                    </select>
                                                    {errors.elect_contractor && <small id="emailHelp" class="text-danger">{errors.elect_contractor?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned Date</label>
                                                    <input
                                                        {...register("elect_assigned_date")}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Assign Date"
                                                        min={currentDate}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['ASSIGNED DATE']}
                                                    />
                                                    {errors.elect_assigned_date && <small id="emailHelp" class="text-danger">{errors.elect_assigned_date?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Electrical Cost</label>
                                                    <input
                                                        {...register("elect_cost")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="cost"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COST']}
                                                    />
                                                    {errors.elect_cost && <small id="emailHelp" class="text-danger">{errors.elect_cost?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Upload Documents</label>
                                                    <input {...register("elect_document")} type="file" className="makebtnleft" disabled={cardtitle === "View Electrical & Plumbing" ? true : false} />
                                                </div>
                                                {/* <div className="form-group mb-3 col-md-4">
                                                    <Link
                                                        onClick={(e) => downloadDocument(e,activeDetails.elect_document)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                </div> */}
                                                {activeuser.elect_document && <div className="form-group mb-3 col-md-4">
                                                    {/* <Link
                                                        onClick={(e) => downloadDocument(e,activeuser.document)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i> {activeuser.document}
                                                    </Link> */}
                                                    <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.elect_document)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.elect_document)}</a>

                                                </div>}
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Comments</label>
                                                    <textarea
                                                        {...register("comments")}
                                                        rows="10"
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COMMENTS']} />
                                                    {errors.comments && <small id="emailHelp" class="text-danger">{errors.comments?.message}</small>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className="form-group mb-3 col-md-3">
                        </div> */}
                                                {/* {cardtitle === "View Electrical & Plumbing" ? */}
                                                    {/* <> */}
                                                    {/* </> :  */}
                                                    <>
                                                        <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : "col-md-3"} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : ""}}>
                                                            <button type="submit" className="btn btn-dark"  style={{display: cardtitle === "View Electrical & Plumbing" ? "none" : ""}}>
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : "col-md-3"} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : ""}}>
                                                            <button type="button" disabled={ShowApprove} className="btn btn-secondary" onClick={(e) => updateWFStatus('Approved')} style={{display: cardtitle === "View Electrical & Plumbing" ? "none" : ""}}>
                                                                Complete
                                                            </button>
                                                        </div>
                                                        <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                            
                                                        </div>
                                                        <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                            
                                                        </div></>
                                                    {/* } */}
                                                <div className={`${cardtitle === "View Electrical & Plumbing" ? "col-md-2" : "col-md-3"} form-group mb-3 text-center`}>
                                                    <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                        Back
                                                    </button>
                                                </div>
                                                <div className={`${cardtitle === "View Electrical & Plumbing" ? "col-md-2" : "col-md-3 text-center"} form-group mb-3 text-center`}>
                                                    <button type="button" className="btn btn-primary" onClick={Print}>
                                                        Print
                                                    </button>
                                                </div>
                                                {/* <div className="form-group mb-3 col-md-3"></div> */}
                                            </div>
                                        </form>
                                        : 
                                        
                                        <>
                                        <div className="basic-form">
                                           <div className="row">
                                               <div className="form-group mb-3 col-md-4">
                                                   <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                               </div>
                                           </div>
                                       </div>
                                       <br/>
                                       <Table responsive className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>S NO.</strong>
                                                    </th>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>FIRST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>LAST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>ELECTRICAL ASSIGNED DATE</strong>
                                                    </th>
                                                    <th>
                                                        <strong>PLUMBING ASSIGNED DATE</strong>
                                                    </th>
                                                    <th>
                                                        FLAG
                                                    </th>
                                                    <th>
                                                        <strong>Actions</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                    return [
                                                        <tr key={i}>
                                                            <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                            <td>{item.member_name}</td>
                                                            <td>{item.first_name}</td>
                                                            <td>{item.last_name}</td>
                                                            <td>{item.elect_assigned_date}</td>
                                                            <td>{item.plumb_assigned_date}</td>
                                                            <td><i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i></td>
                                                            <td><div className="d-flex">
                                                                <Link
                                                                    onClick={(e) => viewuser(e, item)}
                                                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    onClick={(e) => edituser(e, item)}
                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-pencil"></i>
                                                                </Link>
                                                                <Link
                                                                    onClick={(e) => deleteuser(e, item)}
                                                                    className="btn btn-danger shadow btn-xs sharp"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Link>
                                                            </div></td>
                                                        </tr>
                                                    ];
                                                })}
                                            </tbody>
                                        </Table>
                                        <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                    }
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Approved">
                                <div className="row">
                                    {Showform === true
                                        ?
                                        <div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>First Name</label>
                                                    <input
                                                        {...registerForm1("first_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        disabled={true}
                                                        value={activeuser['FIRST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Last Name</label>
                                                    <input
                                                        {...registerForm1("last_name")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Last Name"
                                                        disabled={true}
                                                        value={activeuser['LAST NAME']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Address</label>
                                                    <input
                                                        {...registerForm1("address")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Address"
                                                        disabled={true}
                                                        value={activeuser['ADDRESS']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Phone</label>
                                                    <input
                                                        {...registerForm1("mobile_phone")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        disabled={true}
                                                        value={activeuser['PHONE NO']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>State</label>
                                                    <input
                                                        {...registerForm1("state")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="State"
                                                        disabled={true}
                                                        value={activeuser['STATE']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>City</label>
                                                    <input
                                                        {...registerForm1("city")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="City"
                                                        disabled={true}
                                                        value={activeuser['CITY']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Zipcode</label>
                                                    <input
                                                        {...registerForm1("AL")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Zipcode"
                                                        disabled={true}
                                                        value={activeuser['ZIP CODE']}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Plumbing Contractor</label>
                                                    <select
                                                        {...registerForm1("plumb_contractor")}
                                                        defaultValue={"option"}
                                                        id="SponsorinputState"
                                                        className="form-control"
                                                        //value={activeuser['PROFESSIONAL NAME'] || "Lucy McKenzie"}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                    >
                                                        {usertable.map((item, i) => {
                                                            if (item.scope == 'Plumbing') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} - {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                    </select>
                                                    {errors.plumb_contractor && <small id="emailHelp" class="text-danger">{errors.plumb_contractor?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned Date</label>
                                                    <input
                                                        {...registerForm1("plumb_assigned_date")}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Assign Date"
                                                        min={currentDate}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['ASSIGNED DATE']}
                                                    />
                                                    {errors.plumb_assigned_date && <small id="emailHelp" class="text-danger">{errors.plumb_assigned_date?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Plumbing Cost</label>
                                                    <input
                                                        {...registerForm1("plumb_cost")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="cost"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COST']}
                                                    />
                                                    {errors.plumb_cost && <small id="emailHelp" class="text-danger">{errors.plumb_costcost?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Upload Documents</label>
                                                    <input {...registerForm1("plumb_document")} type="file" className="makebtnleft" disabled={cardtitle === "View Electrical & Plumbing" ? true : false} />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Electrical Contractor</label>
                                                    <select
                                                        {...registerForm1("elect_contractor")}
                                                        defaultValue={"option"}
                                                        id="SponsorinputState"
                                                        className="form-control"
                                                        //value={activeuser['PROFESSIONAL NAME'] || "Lucy McKenzie"}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                    >
                                                        {usertable.map((item, i) => {
                                                            if (item.scope == 'Electrical') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} - {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                    </select>
                                                    {errors.elect_contractor && <small id="emailHelp" class="text-danger">{errors.elect_contractor?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Assigned Date</label>
                                                    <input
                                                        {...registerForm1("elect_assigned_date")}
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Assign Date"
                                                        min={currentDate}
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['ASSIGNED DATE']}
                                                    />
                                                    {errors.elect_assigned_date && <small id="emailHelp" class="text-danger">{errors.elect_assigned_date?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Electrical Cost</label>
                                                    <input
                                                        {...registerForm1("elect_cost")}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="cost"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COST']}
                                                    />
                                                    {errors.elect_cost && <small id="emailHelp" class="text-danger">{errors.elect_cost?.message}</small>}
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Upload Documents</label>
                                                    <input {...registerForm1("elect_document")} type="file" className="makebtnleft" disabled={cardtitle === "View Electrical & Plumbing" ? true : false} />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Comments</label>
                                                    <textarea
                                                        {...registerForm1("comments")}
                                                        rows="10"
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        disabled={cardtitle === "View Electrical & Plumbing" ? true : false}
                                                        value={activeuser['COMMENTS']} />
                                                    {errors.comments && <small id="emailHelp" class="text-danger">{errors.comments?.message}</small>}
                                                </div>
                                                <div className="row">
                                                    {/* <div className="form-group mb-3 col-md-3">
                        </div> */}
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                            
                                                    </div>
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                        
                                                    </div>
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                        
                                                    </div>
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "electrical" : ""} form-group mb-3 col-md-2 text-center`} style={{display: cardtitle === "View Electrical & Plumbing" ? "block" : "none"}}>
                                                        
                                                    </div>
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "col-md-2" : "col-md-3"} form-group mb-3 text-center`}>
                                                        <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className={`${cardtitle === "View Electrical & Plumbing" ? "col-md-2" : "col-md-3 text-center"} form-group mb-3 text-center`}>
                                                        <button type="button" className="btn btn-success" onClick={Print}>
                                                            Print
                                                        </button>
                                                    </div>
                                                    {/* <div className="form-group mb-3 col-md-3"></div> */}
                                                </div>
                                            </div>
                                        </div>

                                        : 
                                        <>
                     <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <Table responsive className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>S NO.</strong>
                                                    </th>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>FIRST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>LAST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>ELECTRICAL ASSIGNED DATE</strong>
                                                    </th>
                                                    <th>
                                                        <strong>PLUMBING ASSIGNED DATE</strong>
                                                    </th>
                                                    <th>
                                                        FLAG
                                                    </th>
                                                    <th>
                                                        <strong>Actions</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                    return [
                                                        <tr key={i}>
                                                              <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                            <td>{item.member_name}</td>
                                                            <td>{item.first_name}</td>
                                                            <td>{item.last_name}</td>
                                                            <td>{item.elect_assigned_date}</td>
                                                            <td>{item.plumb_assigned_date}</td>
                                                            <td><i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i></td>
                                                            <td><div className="d-flex">
                                                                <Link
                                                                    onClick={(e) => viewuser(e, item)}
                                                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                                <Link
                                                                    onClick={(e) => deleteuser(e, item)}
                                                                    className="btn btn-danger shadow btn-xs sharp"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Link>
                                                            </div></td>
                                                        </tr>
                                                    ];
                                                })}
                                            </tbody>
                                        </Table>
                                        <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                    }
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </>}
        </>
    )
}
