
import React, { Fragment, useEffect, useState } from "react";
//import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
//import data from "../../components/table/tableData";
import {
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
//import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import BBUWP from "../../images/iwashwelllogowhite.png";
import QRPIC from "../../images/qr.png";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import * as apiServices from '../../services/apiServices'
export default function CustomerInvoiceDownload({ PrintValues }) {
  const [paymentHistable, setpaymentHistable] = useState([]);
  const [invioceValue, setinvioceValue] = useState({});
  const { MemberId, Month, Year } = useParams();
  function Print(elem) {
    var mywindow = window.open('Iwashwell', 'PRINT', 'height=400,width=600');
    mywindow.document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="./css/style.css">';
    mywindow.document.write(document.getElementById('printableCustomerdiv').innerHTML);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
  }
  const numberToMonth = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Ensure the monthNumber is within the valid range
    const normalizedMonth = Math.max(1, Math.min(12, Month));

    return months[normalizedMonth - 1];
  };
  function ConvertDate(user) {
    const day = '1';
    const month = user.month;
    const year = user.year;

    // Add leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  }
  function ViewInvoice(e, user) {
    e.preventDefault()
    apiServices.ViewInvoice(user.month, MemberId, user.year).then(response => {
      setinvioceValue(response.data[0]);
      //Print('');
    }).catch(error => {

    })

  }
  useEffect(() => {
    // apiServices.getPayHistoryNewDetails(MemberId).then(response => {
    //   setpaymentHistable(response.data.payment_due.filter(s => s.payment_date != '' && s.payment_date != null));
    // }).catch(error => {

    // })
    apiServices.ViewInvoice(Month, MemberId, Year).then(response => {
      setinvioceValue(response.data[0]);
      //Print('');
    }).catch(error => {

    })
  }, [])

  const centeredDivStyle = {
    width: '50%', // Adjust the width as needed
    margin: '0 auto', // This centers the div horizontally
    padding: '20px', // Add padding for gaps on both sides
    boxSizing: 'border-box', // Include padding and border in the total width
    //textAlign: 'center', // Center the content inside the div
    border: '1px solid #ccc', // Optional: Add a border for visibility
  };
  return (
    <>
      <div style={centeredDivStyle}>
        <div className="row">
          <div className="form-group mb-3 col-md-2">
            <button type="button" className="btn btn-danger" onClick={Print}>
              Print
            </button>
          </div>
        </div>
        <div className="pdf-page" id="printableCustomerdiv">
          <table className="table col-md-12" style={{ width: '100%' }}>
            <tr>
              <td>
                <span style={{ textAlign: 'left' }}>
                  <img style={{
                  }} src={BBUWP} alt="" width="100" height="100" />
                </span>
              </td>
              <td><h1 style={{ textAlign: 'center' }}><strong><big>Invoice</big></strong></h1></td>
              <td style={{ textAlign: 'right' }}>
                <span>
                  <img style={{
                  }} src={QRPIC} alt="" width="100" height="100" />
                </span>
              </td>
            </tr>
            <tr>
            <td colSpan={3}><h3 style={{ textAlign: 'center' }}><strong>BBUWP MEMBER WATER AND SANITATION SERVICE</strong></h3></td>
            </tr>
          </table>


          <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={2}>
                  Black Belt Unincorporated Wastewater Progfram|527 Herron street | Montgomery, al | (334) 324-6430
                </td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td style={{ width: '482px;height:133px;', border: '1px solid #dee2e6' }} >
                  <p>DATE:</p>

                  <p><span style={{ color: '#0000FF' }}><strong>{numberToMonth(Month)} 1 {Year}</strong></span></p>

                  <p>INVOICE #:</p>

                  <p><strong><span style={{ color: '#0000FF' }}>{invioceValue.member_invoice_no}&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

                  <p>MEMBER ID#: &nbsp;<span style={{ color: '#0000FF' }}> {invioceValue.member_userid}&nbsp; </span>&nbsp;&nbsp;&nbsp; PROJECT id#:&nbsp; <span style={{ color: '#0000FF' }}> {invioceValue.unz_id} </span></p>

                  <p>&nbsp;</p>


                </td>
                <td style={{ border: '1px solid #dee2e6' }}>
                  <p>TO<span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.member_name}</span></p>

                  <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.address}</span></p>

                  <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.city}</span></p>

                  <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.state}</span></p>
                  <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.AL}</span></p>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ width: '240px;height:33px;' }}>
                  <p>BBUWP CONTACT PERSON <span style={{ color: '#0000FF' }}>Bridget</span></p>
                </td>
                <td style={{ width: '204px;height:33px;' }}>
                  <p>&nbsp;CONTACT#&nbsp; <span style={{ color: '#0000FF' }}>123-456-2341</span></p>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={2}><strong>Payment Terms</strong></td>
                <td colSpan={2}><strong>Due Upon Receipt</strong></td>
              </tr>
            </tbody>
          </table>

          <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
            <thead>
              <tr>
                <th scope="col">QTY</th>
                <th scope="col">DESCRIPTION</th>
                {/* <th scope="col">UNIT PRICE</th> */}
                <th scope="col">COST</th>
              </tr>
              <tr>
                <th scope="col">1</th>
                <th scope="col">{numberToMonth(Month)} Monthly Operations and Maintainence</th>
                {/* <th scope="col">$20</th> */}
                <th scope="col">$20</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td colSpan={2}></td>
                <td>SUB TOTAL</td>
                <td>$20</td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td>SALES TAX </td>
                <td>$0</td>
              </tr> */}
              <tr>
                <td></td>
                <th><strong>TOTAL</strong></th>
                <th>$20</th>
              </tr>
            </tbody>
          </table>


          <p style={{ textAlign: 'center' }}><strong>Make all checks payable to: BBUWP</strong></p>

          <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Bank]</strong></span></p>

          <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Deposit account #]</strong></span></p>

          <p>NOTICE:</p>

          <p>Add specific notifications here</p>

          <p>[ ] Keep Reccord of Payment Send Picture of Deposit Slip to cell number xxx-xxx-xxxx</p>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <table className="table col-md-12" style={{ width: '100%' }}>
          <tr>
            <td>
              <span style={{ textAlign: 'left' }}>
                <img style={{
                }} src={BBUWP} alt="" width="100" height="100" />
              </span>
            </td>
            <td><h1 style={{ textAlign: 'center' }}><strong><big>Invoice</big></strong></h1></td>
            <td style={{ textAlign: 'right' }}>
              <span>
                <img style={{
                }} src={QRPIC} alt="" width="100" height="100" />
              </span>
            </td>
          </tr>
        </table>


        <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={2}>
                Black Belt Unincorporated Wastewater Progfram|527 Herron street | Montgomery, al | (334) 324-6430
              </td>
            </tr>
            <tr>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <td style={{ width: '482px;height:133px;', border: '1px solid #dee2e6' }} >
                <p>DATE:</p>

                <p><span style={{ color: '#0000FF' }}><strong>[January 1 2024]&lt;-automatically generated)</strong></span></p>

                <p>INVOICE #:</p>

                <p><strong><span style={{ color: '#0000FF' }}>[2024-00001-]&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

                <p>CUSTOMER ID#: &nbsp;<span style={{ color: '#0000FF' }}>[ XXXXXX&nbsp; ]</span>&nbsp;&nbsp;&nbsp; PROJECT id#:&nbsp; <span style={{ color: '#0000FF' }}>[ UNZ XXXX ]</span></p>

                <p>&nbsp;</p>


              </td>
              <td style={{ border: '1px solid #dee2e6' }}>
                <p>TO<span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [NAME]</span></p>

                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ADDRESS]</span></p>

                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [CITY/TOWN]</span></p>

                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ZIP CODE]</span></p>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '240px;height:33px;' }}>
                <p>BBUWP CONTACT PERSON <span style={{ color: '#0000FF' }}>[Bridget]</span></p>
              </td>
              <td style={{ width: '204px;height:33px;' }}>
                <p>&nbsp;CONTACT#&nbsp; <span style={{ color: '#0000FF' }}>[ 123-456-2341</span></p>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}><strong>Payment Terms</strong></td>
              <td colSpan={2}><strong>Due Upon Receipt</strong></td>
            </tr>
          </tbody>
        </table>

        <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
          <thead>
            <tr>
              <th scope="col">QTY</th>
              <th scope="col">DESCRIPTION</th>
              <th scope="col">UNIT PRICE</th>
              <th scope="col">LINE TOTAL</th>
            </tr>
            <tr>
              <th scope="col">1</th>
              <th scope="col">January Monthly Operations and Maintainence</th>
              <th scope="col">$20</th>
              <th scope="col">$20</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}></td>
              <td>SUB TOTAL</td>
              <td>$20</td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td>SALES TAX </td>
              <td>$0</td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td><strong>TOTAL</strong></td>
              <td>$20</td>
            </tr>
          </tbody>
        </table>


        <p style={{ textAlign: 'center' }}><strong>Make all checks payable to: BBUWP</strong></p>

        <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Bank]</strong></span></p>

        <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Deposit account #]</strong></span></p>

        <p>NOTICE:</p>

        <p>Add specific notifications here</p>

        <p>[ ] Keep Reccord of Payment Send Picture of Deposit Slip to cell number xxx-xxx-xxxx</p>
      </div>
      <div className="row">
        {paymentHistable.length > 0 &&
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <strong>#</strong>
                </th>
                <th>
                  <strong>DATE</strong>
                </th>
                <th><strong>ACTION</strong></th>
              </tr>
            </thead>
            <tbody>
              {paymentHistable.map((item, i) => {
                return [
                  <tr key={i}>
                    <td>
                      {i + 1}</td>
                    <td>{ConvertDate(item)}</td>
                    <td>
                      <Link
                        onClick={(e) => ViewInvoice(e, item)}
                        className="btn btn-warning shadow btn-xs sharp me-1"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                ];
              })}
            </tbody>
          </Table>
        }
      </div>
    </>
  )
}
