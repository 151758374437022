import React, { Fragment } from "react";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import PageTItle from "../../layouts/PageTitle";
import FeesCollection from "../../components/table/FeesCollection"
import  data from "../../components/table/tableData.js";

export default function Facility() {
    return (
        <Fragment>
            <PageTItle activeMenu="Add Location" motherMenu="Facility" pageContent="Add Location" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Location</h4>
                    </div>
                    <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                            <label>Location</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Location"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-4">
                                <label>Type</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Old</option>
                                    <option>New</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-3" style={{display:"flex", alignSelf:"flex-end",justifyContent: "center"}}>
                            <label>No. Of Toilet</label>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                            <label>Men</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group mb-3 col-md-3">
                            <label>Women</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group mb-3 col-md-3">
                            <label>Status</label>
                            <select
                                defaultValue={"option"}
                                id="inputState"
                                className="form-control"
                            >
                                <option value="option" disabled>
                                Choose...
                                </option>
                                <option>Active</option>
                                <option>InActive</option>
                            </select>
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-group mb-3 col-md-3">
                        </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-warning">
                                    Cancel
                                </button>
                            </div>
                        <div className="form-group mb-3 col-md-3"></div>
                        </div>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>
            <FeesCollection tablename="List of Facility" tabledata={data.listoffacilityTable} />
        </Fragment>
    )
}
