import React, { Fragment , useState  } from "react";
import PageTItle from "../../layouts/PageTitle";
import  data from "../../components/table/tableData.js";
import {
    Table,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Schematics() {
    const [btntitle, setbtntitle] = useState("Add Schematics");
    const [cardtitle, setcardtitle] = useState("List of Schematics");
    const [Showform, setShowform] = useState(false);
    const [schematictabledatatable, setusertable] = useState(data.schematictabledata);
    const [activeuser, setactiveuser] = useState({});

    function checkboxFun(option) {
        console.log(option)
    }

    function cardbtn() {
        if (btntitle === 'Add Schematics' || btntitle === 'Edit Schematics' || btntitle === 'View Schematics') {
            setbtntitle("Back");
            setcardtitle("Add Schematics")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Schematics");
            setcardtitle("List of Schematics")
            setactiveuser({})
            setShowform(false)
        }
    }

    function viewuser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View Schematics")
        setactiveuser(user)
        setShowform(true)
    }

    function edituser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit Schematics")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e,user) {
        e.preventDefault()
        setactiveuser(user)
        notifyError();
    }

    const notifyError = () => {
        toast.error("Schematics Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    return (
        <Fragment>
            <PageTItle activeMenu="Add Schematics" motherMenu="Schematics" pageContent="Add Schematics" />
            <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="col-xl-6 col-lg-6">
                            <h4 className="card-title">{cardtitle}</h4>
                        </div>
                        <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        {Showform === true 
                            ?
<div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Schematic</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Schematic"
                                        disabled={cardtitle === "View Schematics" ? true : false}
                                        value={activeuser?.['schematic']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>System</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="System"
                                        disabled={cardtitle === "View Schematics" ? true : false}
                                        value={activeuser?.['system']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Schematic Document</label>
                                    <input
                                        type="file"
                                        className="makebtnleft"
                                        disabled={cardtitle === "View Schematics" ? true : false}
                                    />
                                    </div>
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                            Save
                                        </button>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                            Cancel
                                        </button>
                                    </div>
                                <div className="form-group mb-3 col-md-3"></div>
                                </div>
                            </form>
                    </div>
                            :
                            <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>S NO.</strong>
                    </th>
                    <th>
                      <strong>SCHEMATICS</strong>
                    </th>
                    <th>
                      <strong>SYSTEM</strong>
                    </th>
                    <th>
                      <strong>Actions</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {schematictabledatatable.map((item, i) => {
                    return [
                        <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item['schematic']}</td>
                            <td>{item['system']}</td>
                            <td><div className="d-flex">
                        <Link
                          onClick={(e)=> viewuser(e,item)}
                          className="btn btn-warning shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div></td>
                        </tr>
                    ];
                })}
                </tbody>
              </Table>
              
                        }
                    
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}
