import React,{useState, Fragment, useEffect} from 'react';
import PageTItle from "../../layouts/PageTitle";
import {Button, Modal, Carousel } from "react-bootstrap";

function Payment() {

    const [selectuser, setselectuser] = useState('')
    const [selectmember,setselectmember] = useState('')
    const [firstname , setfirstname] = useState('')
    const [lastname ,setlastname ] = useState('')
    const [email , setemail] = useState('')
    const [phone , setphone] = useState('')
    const [valueservice, setvalueservice] = useState('')
    const [valueproduct, setvalueproduct] = useState('')
    const [payment , setpayment] = useState('')

    function seletedUser(e) {
        e.preventDefault()
        setselectuser(e.target.value)
        if(e.target.value == 'nonmember' || e.target.value == 'others' || e.target.value == '' ){
            setselectmember('')
            setfirstname('')
            setlastname('')
            setemail('')
            setphone('')
        }
        if (e.target.value == 'member') {
            setfirstname('')
            setlastname('')
            setemail('')
            setphone('')
        }
    }

    function seletedMember(e) {
        e.preventDefault()
        setselectmember(e.target.value)
        setfirstname(e.target.value)
        setlastname('Doe')
        setemail(e.target.value + '@test.com')
        setphone('1234567890')
    }

    function seletedValueservice(e) {
        e.preventDefault()
        setvalueservice(e.target.value)
    }

    function seletedValueproduct(e) {
        e.preventDefault()
        setvalueproduct(e.target.value)
    }

    function checkboxFun(option) {
        console.log(option)
    }

    function seletedPayment(e) {
        e.preventDefault()
        setpayment(e.target.value)
    }
  return (
        <Fragment>
            <PageTItle activeMenu="Payment" motherMenu="Payment" pageContent="Payment" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="form-group mb-6 col-md-6">
                                    <label>User</label>
                                    <select
                                        defaultValue={"option"}
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => seletedUser(e)}
                                    >
                                        <option value="" >
                                        Choose...
                                        </option>
                                        <option value="member" >Member</option>
                                        <option value="nonmember" >Non Member</option>
                                        <option value="others" >Others</option>
                                    </select>
                                </div>
                                {
                                  selectuser === 'member' ?
                                  <div className="form-group mb-6 col-md-6">
                                    <label>Member List</label>
                                    <select
                                        defaultValue={"option"}
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => seletedMember(e)}
                                        disabled={selectuser != 'member'}
                                    >
                                        <option value="" >
                                        Choose...
                                        </option>
                                        <option value="James" >James</option>
                                        <option value="Danny" >Danny</option>
                                        <option value="Marc" >Marc</option>
                                        <option value="Robert" >Robert</option>
                                        <option value="Tony" >Tony</option>
                                        <option value="Ruben" >Ruben</option>
                                        <option value="Michael" >Michael</option>
                                        <option value="Trvor" >Trvor</option>
                                        <option value="Franklin" >Franklin</option>
                                        <option value="William" >William</option>
                                        <option value="David" >David</option>
                                        <option value="Stanley" >Stanley</option>
                                        <option value="Richard" >Richard</option>
                                        <option value="Nathaniel" >Nathaniel</option>
                                        <option value="Leonard" >Leonard</option>
                                        <option value="Xavier" >Xavier</option>
                                    </select>
                                </div>
                                  :
                                  <></>
                                }
                                </div>
                                <div className="row">
                                <div className="form-group mt-3 mb-3 col-md-3">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        id="firstname"
                                        className="form-control"
                                        placeholder="First Name"
                                        disabled={selectuser == 'member'}
                                        value={firstname}
                                        onChange={(e) => setfirstname(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mt-3 mb-3 col-md-3">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        id="lastname"
                                        className="form-control"
                                        placeholder="Last Name"
                                        disabled={selectuser == 'member'}
                                        value={lastname}
                                        onChange={(e) => setlastname(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mt-3 mb-3 col-md-3">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Email"
                                        disabled={selectuser == 'member'}
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mt-3 mb-3 col-md-3">
                                    <label>Phone No.</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        className="form-control"
                                        placeholder="Phone No."
                                        disabled={selectuser == 'member'}
                                        value={phone}
                                        onChange={(e) => setphone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label>Basic Service</label>
                                <div className="btn-group mb-1 col-md-3">
                                    <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox1"
                                        onClick={() => checkboxFun("bloodtest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox1"
                                    >Water</label>
                                    </div>
                                    
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox2"
                                        onClick={() => checkboxFun("bptest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox2"
                                    >Sanitization</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-3 mb-6 col-md-6">
                                    <label>Value Added Service</label>
                                    <select
                                        defaultValue={"option"}
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => seletedValueservice(e)}
                                    >
                                        <option value="" >
                                        Choose...
                                        </option>
                                        <option value="health" >Health Related</option>
                                        <option value="nonhealth" >Non Health Related</option>
                                    </select>
                                </div>
                            </div>
                            {
                                valueservice == 'health' ?
                                <div className='row mt-3'>
                                    <label>Health Related Service</label>
                                    <div className="btn-group mb-1 col-md-3">
                                    <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox3"
                                        onClick={() => checkboxFun("bloodtest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox3"
                                    >Blood Test</label>
                                    </div>
                                    
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox4"
                                        onClick={() => checkboxFun("bptest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox4"
                                    >BP Test</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox5"
                                        onClick={() => checkboxFun("ecg")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox5"
                                    >ECG</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox6"
                                        onClick={() => checkboxFun("xray")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox6"
                                    >X-Ray</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox7"
                                        onClick={() => checkboxFun("others")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox7"
                                    >Others</label>
                                    </div>
                                </div>
                                </div>
                                
                                :
                                <>
                                </>
                            }
                            {
                                valueservice == 'nonhealth' ?
                                <div className='row mt-3'>
                                    <label>Non Health Related Service</label>
                                    <div className="btn-group mb-1 col-md-3">
                                    <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox8"
                                        onClick={() => checkboxFun("bloodtest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox8"
                                    >Essential Delivery</label>
                                    </div>
                                    
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox9"
                                        onClick={() => checkboxFun("bptest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox9"
                                    >Water Delivery</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox10"
                                        onClick={() => checkboxFun("ecg")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox10"
                                    >Toilet Supplies</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox11"
                                        onClick={() => checkboxFun("xray")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox11"
                                    >Others</label>
                                    </div>
                                </div>
                                </div>
                                :
                                <>
                                </>
                            }
                            <div className='row'>
                                <div className="form-group mt-3 mb-6 col-md-6">
                                    <label>Value Added Prouct</label>
                                    <select
                                        defaultValue={"option"}
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => seletedValueproduct(e)}
                                    >
                                        <option value="" >
                                        Choose...
                                        </option>
                                        <option value="health" >Health Related</option>
                                        <option value="nonhealth" >Non Health Related</option>
                                    </select>
                                </div>
                            </div>
                            {
                                valueproduct == 'health' ?
                                <div className='row mt-3'>
                                    <label>Health Related Product</label>
                                    <div className="btn-group mb-1 col-md-3">
                                    <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox12"
                                        onClick={() => checkboxFun("bloodtest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox12"
                                    >Sanitizer</label>
                                    </div>
                                    
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox13"
                                        onClick={() => checkboxFun("bptest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox13"
                                    >Bandage</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox14"
                                        onClick={() => checkboxFun("ecg")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox14"
                                    >First Aid Kit</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox15"
                                        onClick={() => checkboxFun("xray")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox15"
                                    >Tablets</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox16"
                                        onClick={() => checkboxFun("others")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox16"
                                    >Others</label>
                                    </div>
                                </div>
                                </div>
                                
                                :
                                <>
                                </>
                            }
                            {
                                valueproduct == 'nonhealth' ?
                                <div className='row mt-3'>
                                    <label>Non Health Related Product</label>
                                    <div className="btn-group mb-1 col-md-3">
                                    <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox17"
                                        onClick={() => checkboxFun("bloodtest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox17"
                                    >Fruits</label>
                                    </div>
                                    
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox18"
                                        onClick={() => checkboxFun("bptest")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox18"
                                    >Vegetables</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox19"
                                        onClick={() => checkboxFun("ecg")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox19"
                                    >Milk</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox20"
                                        onClick={() => checkboxFun("xray")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox20"
                                    >Bread</label>
                                    </div>
                                </div>
                                <div className="btn-group mb-1 col-md-3">
                                <div className="form-check custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox21"
                                        onClick={() => checkboxFun("xray")}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkbox21"
                                    >Others</label>
                                    </div>
                                </div>
                                </div>
                                :
                                <>
                                </>
                            }
                            <div className="row mt-3">
                                <div className="form-group mb-6 col-md-6">
                                    <label>Payment Option</label>
                                    <select
                                        defaultValue={"option"}
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => seletedPayment(e)}
                                    >
                                        <option value="" >
                                        Choose...
                                        </option>
                                        <option value="epayment" >Auto E-Payment</option>
                                        <option value="cash" >Cash</option>
                                        <option value="cheque" >Mailed Cheque</option>
                                        <option value="sponsor" >Sponser 100%</option>
                                        <option value="other" >Sponsor Other</option>
                                        <option value="others" >Others</option>
                                    </select>
                                </div>
                            </div>
                            {payment == 'cash' ?
                            <div className="row mt-3">
                                <div className="form-group mb-6 col-md-6">
                                <label>Cash</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Cash"
                                    />
                                </div>
                            </div>
                            :
                            <></>}
                            <div className="row mt-3">
                            <div className="form-group mb-3 col-md-4">
                            </div>
                                <div className="form-group mb-3 col-md-4">
                                    <button type="submit" className="btn btn-primary">
                                        Pay
                                    </button>
                                </div>
                            <div className="form-group mb-3 col-md-4"></div>
                            </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
  )
}

export default Payment