// Loader.js
import React from 'react';
//import loaderGif from '../../../images/Wedges-3s-207px.gif';
//import loaderGif from '../../../images/Glowing ring.gif';
import loaderGif from '../../../images/Wedges-3s-241px.gif';
const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={loaderGif} alt="Loading..." />
    </div>
  );
};

export default Loader;
