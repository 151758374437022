import React, { Fragment } from "react";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import PageTItle from "../../layouts/PageTitle";
import FeesCollection from "../../components/table/FeesCollection"
import  data from "../../components/table/tableData.js";

export default function Supplies() {
    return (
        <Fragment>
            <PageTItle activeMenu="Order Supplies" motherMenu="Supplies" pageContent="Order Supplies" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Order Supplies</h4>
                    </div>
                    <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                            <label>Facility Location</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value="Witsand"
                                disabled
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Warehouse Location</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Warehouse Location"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Product Name"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Quantity Available</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Quantity Available"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Cost of Product</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Cost of Product"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Lead Time</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Lead Time"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Enter Quantity</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quantity"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Total Cost</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Total Cost"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Status</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Pending</option>
                                    <option>Delivered</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-group mb-3 col-md-3">
                        </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-primary">
                                    Order
                                </button>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-warning">
                                    Cancel
                                </button>
                            </div>
                        <div className="form-group mb-3 col-md-3"></div>
                        </div>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>
            <FeesCollection tablename="List of Orders" tabledata={data.listoforderTable} />
        </Fragment>
    )
}
