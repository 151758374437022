import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//import BBUWP from "../../../images/BBUWP.png";
import BBUWP from "../../../images/iwashwelllogowhite.png";
export default function WorkflowHeader({ activeMember }) {
    const [activeuser, setactiveuser] = useState({});
    useEffect(() => {
        if (activeMember != undefined) {
            apiServices.getMemberHeaderList(activeMember).then(response => {
                setactiveuser(response.data[0]);
            });
        }
    }, [activeMember])
    return (
        <>
            <div>
                <div className="style-1 accordion">
                    <div class="accordion_body accordion-collapse collapse show">
                        <div class="payment-details accordion-body-text">
                            <div className="col-md-12 d-flex">
                                <div class="col-md-3">
                                    <p class="fs-12 mb-2">First Name</p>
                                    <span class="font-w500">{activeuser.first_name}</span>
                                </div>
                                <div class="col-md-3">
                                    <p class="fs-12 mb-2">Last Name</p>
                                    <span class="font-w500">{activeuser.last_name}</span>
                                </div>
                                <div class="col-md-3">
                                    <p class="fs-12 mb-2">Address</p>
                                    <span class="font-w500">{activeuser.address}</span>
                                </div>
                                <div class="col-md-3">
                                    <p class="fs-12 mb-2">Phone</p>
                                    <span class="font-w500">{activeuser.mobile_phone}</span>
                                </div>
                            </div>
                        </div>
                        <div class="payment-details accordion-body-text">

                            <div class="col-md-3">
                                <p class="fs-12 mb-2">State</p>
                                <span class="font-w500">{activeuser.state}</span>
                            </div>
                            <div class="col-md-3">
                                <p class="fs-12 mb-2">City</p>
                                <span class="font-w500">{activeuser.city}</span>
                            </div>
                            <div class="col-md-3">
                                <p class="fs-12 mb-2">Zipcode</p>
                                <span class="font-w500">{activeuser.AL}</span>
                            </div>
                            <div class="col-md-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
