import React, { Fragment , useEffect, useState  } from "react";
import PageTItle from "../../layouts/PageTitle";
import  data from "../../components/table/tableData.js";
import {
    Table,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as apiServices from '../../../services/apiServices'

const schema = yup.object({
    member_id: yup.string(), 
    project_name: yup.string(), 
    last_paid_amount: yup.mixed(), 
    current_due_amount: yup.mixed(), 
    over_due_amount: yup.mixed(), 
    last_paid_date: yup.string(), 
    current_due_date: yup.string(), 
    //over_due_date: yup.string(), 
    payment_method_others: yup.string(), 
});

export default function PaymentModule() {
    const [paymentmethod,setpaymentmethod] = useState("Money Order");
    const [duemethod,setduemethod] = useState("Current Due");
    const [btntitle, setbtntitle] = useState("Add Project");
    const [cardtitle, setcardtitle] = useState("List of Project");
    const [Showform, setShowform] = useState(false);
    const [projecttable, setprojecttable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [membersList, setMembersList] = useState([]);
    const [paymentTrackId, setPaymentTrackId] = useState('');

    const { register, handleSubmit,formState: { errors }, reset, setValue,getValues  } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            member_id: '', 
            project_name: '', 
            last_paid_amount: '', 
            current_due_amount: '', 
            over_due_amount: '', 
            last_paid_date: '', 
            current_due_date: '', 
            //over_due_date: '', 
            payment_method_others: '', 
          }
    });

    useEffect(()=>{
        getMembers()
        getPayTrackHistory()
    },[])

    const onSubmit = e => {
        e.preventDefault()
        console.log(getValues())
        if(cardtitle === "Add Project"){
            apiServices.addPaymentTrack(getValues()).then(response => {
                console.log(response)
                reset()
              }).catch(error => {
    
            })
        }
        else{
            apiServices.updatePaymentTrack(getValues(),paymentTrackId).then(response => {
                console.log(response)
                reset()
              }).catch(error => {
    
            })
        }
        
    };

    const getPayTrackHistory = () =>{
        apiServices.getPayHistoryList().then(response => {
        console.log(response)
        setprojecttable(response.data)

        }).catch(error => {

        })
    }

    const getMembers = () =>{
        apiServices.getMemberList().then(response => {
        console.log(response)
        setMembersList(response.data)
        }).catch(error => {

        })
    }

    function checkboxFun(option) {
        console.log(option)
    }

    function cardbtn() {
        if (btntitle === 'Add Project' || btntitle === 'Edit Project' || btntitle === 'View Project') {
            setbtntitle("Back");
            setcardtitle("Add Project")
            setactiveuser({})
            setShowform(true)
            reset()
        } else {
            setbtntitle("Add Project");
            setcardtitle("List of Project")
            setactiveuser({})
            setShowform(false)
            getPayTrackHistory()
        }
    }

    function viewuser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View Project")
        setactiveuser(user)
        setShowform(true)
        setValue('member_id', user.member_id)
        setValue('project_name', user.project_name )
        setValue('last_paid_amount', user.last_paid_amount )
        setValue( 'current_due_amount', user.current_due_amount)
        setValue('over_due_amount', user.over_due_amount) 
        setValue('last_paid_date', user.last_paid_date)
        setValue( 'current_due_date', user.current_due_date)
        //setValue('over_due_date', user.over_due_date)
        setValue( 'payment_date', user.payment_date)
        setValue( 'payment_method_others', user.payment_method_others)
    }

    function edituser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit Project")
        setactiveuser({})
        setShowform(true)
        setPaymentTrackId(user.id)
        setValue('member_id', user.member_id)
        setValue('project_name', user.project_name )
        setValue('last_paid_amount', user.last_paid_amount )
        setValue( 'current_due_amount', user.current_due_amount)
        setValue('over_due_amount', user.over_due_amount) 
        setValue('last_paid_date', user.last_paid_date)
        setValue( 'current_due_date', user.current_due_date)
        //setValue('over_due_date', user.over_due_date)
        setValue( 'payment_date', user.payment_date)
        setValue( 'payment_method_others', user.payment_method_others)
    }

    function deleteuser(e,user) {
        e.preventDefault()
        setactiveuser(user)
        apiServices.deletePaymentTrack(user.id).then(response => {
            notifyError();
            getMembers()
            getPayTrackHistory()
            }).catch(error => {
    
            })
    }

    function payment(e,user) {
        e.preventDefault()
        Swal.fire({
            title: '<strong>Payment</strong>',
            html:
                `Last Paid Amount : `+ user['PAID'] +`</b><br>` +
                `Pending Amount : `+ user['PENDING'] +`</b>` +
                '<input id="swal-input1" class="swal2-input" placeholder="Enter Remarks">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Enter Amount">',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
              'Pay',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              'Cancel',
            cancelButtonAriaLabel: 'Thumbs down'
        })
    }

    const notifyError = () => {
        toast.error("Project Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    return (
        <Fragment>
            {/* <PageTItle activeMenu="Add Project" motherMenu="Project" pageContent="Add Project" /> */}
            <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="col-xl-6 col-lg-6">
                            <h4 className="card-title">{cardtitle}</h4>
                        </div>
                        <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        {Showform === true 
                            ?
                        <div className="basic-form">
                            <form>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                        <label>NAME</label>
                                        <select
                                            {...register("member_id")}
                                            defaultValue={"option"}
                                            id="SponsorinputState"
                                            className="form-control"
                                        >
                                            <option value={""}>Select</option>
                                            {membersList.map((data, i)=>{
                                                return(
                                                    <>
                                                        <option value={data.id}>{data.member_name}</option>     
                                                    </>
                                                )

                                            })}
                                        </select>
                                        {errors.funding_source && <small id="emailHelp" class="text-danger">{errors.funding_source?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Project Name</label>
                                    <input
                                        {...register("project_name")}
                                        type="text"
                                        className="form-control"
                                        placeholder="Project Name"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        // value={activeuser?.['PROJECT NAME']}
                                    />
                                    {errors.project_name && <small id="emailHelp" class="text-danger">{errors.project_name?.message}</small>}
                                    </div>
                                    {/* <div className="form-group mb-3 col-md-4">
                                    <label>Address</label>
                                    <input

                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['ADDRESS']}
                                    />
                                    </div> */}
                                    {/* <div className="form-group mb-3 col-md-4">
                                    <label>Zipcode</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zipcode"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['ZIP CODE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['PHONE NO']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['EMAIL']}
                                    />
                                    </div> */}
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Last Paid Amount</label>
                                    <input
                                        {...register("last_paid_amount")}
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Paid Amount"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['LAST PAID AMOUNT'] || "20"}
                                    />
                                    {errors.last_paid_amount && <small id="emailHelp" class="text-danger">{errors.last_paid_amount?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Current Due Amount</label>
                                    <input
                                        {...register("current_due_amount")}
                                        type="text"
                                        className="form-control"
                                        placeholder="Current Due Amount"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['CURRENT DUE AMOUNT'] || "20"}
                                    />
                                    {errors.current_due_amount && <small id="emailHelp" class="text-danger">{errors.current_due_amount?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>OverDue Amount</label>
                                    <input
                                       {...register("over_due_amount")}
                                        type="text"
                                        className="form-control"
                                        placeholder="OverDue Amount"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['OVERDUE AMOUNT'] || "50"}
                                    />
                                    {errors.over_due_amount && <small id="emailHelp" class="text-danger">{errors.over_due_amount?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Last Paid Date</label>
                                    <input
                                         {...register("last_paid_date")}
                                        type="date"
                                        className="form-control"
                                        placeholder="Last Paid Date"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        // value={activeuser?.['LAST PAID Date']}
                                    />
                                    {errors.last_paid_date && <small id="emailHelp" class="text-danger">{errors.last_paid_date?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Current Due Date</label>
                                    <input
                                         {...register("current_due_date")}
                                        type="date"
                                        className="form-control"
                                        placeholder="Current Due Date"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        // value={activeuser?.['CURRENT DUE DATE']}
                                    />
                                    {errors.current_due_date && <small id="emailHelp" class="text-danger">{errors.current_due_date?.message}</small>}
                                    </div>
                                    {/* <div className="form-group mb-3 col-md-4">
                                    <label>OverDue Date</label>
                                    <input
                                        {...register("over_due_date")}
                                        type="date"
                                        className="form-control"
                                        placeholder="OverDue Date"
                                        // disabled={cardtitle === "View Project" ? true : false}
                                        // value={activeuser?.['OVERDUE DATE']}
                                    />
                                    {errors.over_due_date && <small id="emailHelp" class="text-danger">{errors.over_due_date?.message}</small>}
                                    </div> */}
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Choose Payment Method</label>
                                    <select     
                                        {...register("payment_method_others")}
                                        defaultValue={"option"}
                                        id="ScopeworkinputState"
                                        className="form-control"
                                        // value={activeuser?.['PAYMENT METHOD'] || paymentmethod}
                                        // disabled={cardtitle === "View Project" ? true : false}
                                    >
                                        <option value="Money Order">Money Order</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Cash Reciept">Cash Reciept</option>
                                        <option value="Online Wire Transfer">Online Wire Transfer</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.payment_method_others && <small id="emailHelp" class="text-danger">{errors.payment_method_others?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                 {/* <div className="form-check custom-radio mb-2">
                                 <label>How much do you want to pay</label><br />
                                    <input 
                                        {...register("current_due_amount")}
                                       id="credit"
                                       name="paymentMethod"
                                       type="radio"
                                       className="form-check-input"
                                       value={"CURRENT DUE"}
                                    />
                                    <label
                                       className="form-check-label"
                                       htmlFor="credit"
                                    >
                                       Current Due - $ {activeuser?.['CURRENT DUE'] || "20"}
                                    </label>
                                 </div>
                                 <div className="form-check custom-radio mb-2">
                                    <input
                                        {...register("current_due_amount")}
                                       id="debit"
                                       name="paymentMethod"
                                       type="radio"
                                       className="form-check-input"
                                       value={"OVERDUE"}
                                    />
                                    <label
                                       className="form-check-label"
                                       htmlFor="debit"
                                    >
                                      Over Due - $ {activeuser?.['OVERDUE'] || "50"}
                                    </label>
                                 </div> */}
                              </div>
                                    {/* <div className="form-group mb-3 col-md-4">
                                    <label>When would you like to pay</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Date"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['DATE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Upload {paymentmethod}</label>
                                    <input
                                        type="file"
                                        className="makebtnleft"
                                        disabled={cardtitle === "View Project" ? true : false}
                                    />
                                    </div> */}
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                </div>
                                {cardtitle !== "View User" &&
                                    <>
                                        <div className="form-group mb-3 col-md-3">
                                            <button type="submit" className="btn btn-primary" onClick={(e) => onSubmit(e)}>
                                                Save
                                            </button>
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <button className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                Cancel
                                            </button>
                                        </div>
                                    </>
                                }
                                    
                                <div className="form-group mb-3 col-md-3"></div>
                                </div>
                            </form>
                    </div>
                            :
                            <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>S NO.</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>PROJECT NAME</strong>
                    </th>
                    <th>
                      <strong>LAST PAID AMOUNT</strong>
                    </th>
                    <th>
                      <strong>CURRENT DUE AMOUNT</strong>
                    </th>
                    <th>
                      <strong>OVERDUE AMOUNT</strong>
                    </th>
                    <th>
                      <strong>PAYMENT METHOD</strong>
                    </th>
                    <th>
                      <strong>Actions</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {projecttable.map((item, i) => {
                    return [
                        <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.member_name ? item.member_name : item['member_id']}</td>
                            <td>{item['project_name']}</td>
                            <td>{item['last_paid_amount']}</td>
                            <td>{item['current_due_amount']}</td>
                            <td>{item['over_due_amount']}</td>
                            <td>{item['payment_method_others']}</td>
                            <td><div className="d-flex">
                        <Link
                          onClick={(e)=> viewuser(e,item)}
                          className="btn btn-warning shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div></td>
                        </tr>
                    ];
                })}
                </tbody>
              </Table>
              
                        }
                    
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}
