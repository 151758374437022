import axios from 'axios';
import swal from "sweetalert";
import * as apiService from '../services/apiServices';


import {
    CustomerInvoiceDownload,
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        'email': email,
        'password': password,
        //returnSecureToken: true,
    };
    const headers = {
        'Content-Type': 'application/json',
    };
    const URL = apiService.LoginToapp();

    //  return axios.post(URL,postData).then(response => {
    //      // console.log(response)
    //      // reset()
    //      // cardbtn()
    //  }).catch(error => {

    //  })
    // return axios.post(
    //      `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    //      postData,
    //  );
    return axios.post(
        URL,
        postData,
        { headers }
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    //localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('token', tokenDetails[0].token);


}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        //dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('token');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        const currentUrl = window.location.href;
        if (currentUrl.includes('view_invoice'))
        {
            dispatch(CustomerInvoiceDownload(history));
        }
        else
            dispatch(logout(history));
        return;
    }
    const currentUrl = window.location.href;
    if (currentUrl.includes('view_invoice'))
    {
        dispatch(CustomerInvoiceDownload(history));
    }
    //tokenDetails = JSON.parse(tokenDetailsString);
    tokenDetails = tokenDetailsString;
    let expireDate = new Date() + 2;
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    //const timer = expireDate.getTime() - todaysDate.getTime();
    const timer = 3600 * 1000;
    runLogoutTimer(dispatch, timer, history);
}
