import React, { Fragment , useState  } from "react";
import PageTItle from "../../layouts/PageTitle";
import  data from "../../components/table/tableData.js";
import {
    Table,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BasicDatatable from '../../components/table/BasicDatatable'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    Name: yup.string().required(),
    // age: yup.number().positive().integer().required(),
  }).required();

export default function Users() {
    const [Sponsor, setSponsor] = useState("ARPA");
    const [fileUpload1checkbox, setfileUpload1checkbox] = useState(false);
    const [fileUpload2checkbox, setfileUpload2checkbox] = useState(false);
    const [fileUpload3checkbox, setfileUpload3checkbox] = useState(false);
    const [fileUpload4checkbox, setfileUpload4checkbox] = useState(false);
    const [btntitle, setbtntitle] = useState("Add User");
    const [cardtitle, setcardtitle] = useState("List of Users");
    const [Showform, setShowform] = useState(false);
    const [usertable, setusertable] = useState(data.newusertabledata);
    const [activeuser, setactiveuser] = useState({});


    const { register, handleSubmit,formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => console.log(data);

    function checkboxFun(option) {
        console.log(option)
    }


    function cardbtn() {
        if (btntitle === 'Add User' || btntitle === 'Edit User' || btntitle === 'View User') {
            setbtntitle("Back");
            setcardtitle("Add User")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add User");
            setcardtitle("List of Users")
            setactiveuser({})
            setShowform(false)
        }
    }

    function viewuser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View User")
        setactiveuser(user)
        setShowform(true)
    }

    function edituser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit User")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e,user) {
        e.preventDefault()
        setactiveuser(user)
        notifyError();
    }

    const notifyError = () => {
        toast.error("User Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    return (
        <Fragment>
            <PageTItle activeMenu="Add User" motherMenu="Users" pageContent="Add User" />
            <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="col-xl-6 col-lg-6">
                            <h4 className="card-title">{cardtitle}</h4>
                        </div>
                        <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        {Showform === true 
                            ?
<div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-3">
                                <label>Sponsor</label>
                                <select
                                    defaultValue={"option"}
                                    id="SponsorinputState"
                                    className="form-control"
                                    value={activeuser?.['Sponsor'] || Sponsor}
                                    onChange={(e) => setSponsor(e.target.value)}
                                    disabled={cardtitle === "View User" ? true : false}
                                >
                                    <option value="ARPA">ARPA</option>
                                    <option value="USDA">USDA</option>
                                </select>
                            </div>
                        </div>
                        {Sponsor === "ARPA"
                        ?
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Name</label>
                                    <input
                                        {...register("Name")}
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['NAME']}
                                    />
                                    {errors.Name && <small id="emailHelp" class="text-danger">{errors.Name?.message}</small>}
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Date"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['DATE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Ethnicity</label>
                                        <select
                                            defaultValue={"option"}
                                            id="EthnicityinputState"
                                            className="form-control"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['ETHINICITY']}
                                        >
                                            <option value="option" disabled>
                                            Choose Ethnicity...
                                            </option>
                                            <option value="Caucasian">Caucasian</option>
                                            <option value="African American">African American</option>
                                            <option value="Native American">Native American</option>
                                            <option value="Asian">Asian</option>
                                            <option value="Alaskan Native">Alaskan Native</option>
                                            <option value="Hawaiian">Hawaiian</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['ADDRESS']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['CITY']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>AL</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zipcode"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['ZIP CODE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Home"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['PHONE - HOME']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Cellular"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['PHONE - CELLULAR']}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Property Size</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Property Size"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['PROPERTY SIZE']}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Do you live in an unincorporated area in Lowndes County?</label>
                                            <div className="btn-group makebtnleft pt-1 col-md-3">
                                                <div className="form-check custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox1"
                                                    onClick={() => checkboxFun("yes")}
                                                    disabled={cardtitle === "View User" ? true : false}
                                                    checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="checkbox1"
                                                >Yes</label>
                                                </div>
                                                
                                            </div>
                                            <div className="btn-group pt-1 col-md-3">
                                            <div className="form-check custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox2"
                                                    onClick={() => checkboxFun("no")}
                                                    disabled={cardtitle === "View User" ? true : false}
                                                    checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="checkbox2"
                                                >No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                        <label>Type of residence?</label>
                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("House")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >House</label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-3">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("Mobile Home")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Mobile Home</label>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Water Source</label>
                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("Public")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Public</label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-3">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("Private")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Private (Well)</label>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                        <label># of Occupants  </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="# of Occupants  "
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['OCCUPANTS']}
                                        />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                        <label># of Bedrooms </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="# of Bedrooms "
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['BEDROOMS']}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-12">
                                        <label>What is your current method of sewage disposal?</label>
                                        <div className="btn-group pt-1 col-md-12">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("yes")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Straight pipe or septic system failure affecting a neighboring property.  </label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("no")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                            </div>
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("no")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-12">
                                        <label>Documents Needed</label>
                                        <div className="btn-group pt-1 col-md-12">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => setfileUpload1checkbox(!fileUpload1checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Copy of Driver’s License/Non-Driver ID</label>
                                            </div>
                                            {fileUpload1checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false} />
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload2checkbox(!fileUpload2checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Proof of Land Ownership</label>
                                            </div>
                                            {fileUpload2checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload3checkbox(!fileUpload3checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Copy of Water Usage </label>
                                            </div>
                                            {fileUpload3checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload4checkbox(!fileUpload4checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Proof of Income (example: Tax Return/SSI)</label>
                                            </div>
                                            {fileUpload4checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-primary">
                                            Save
                                        </button>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                            Cancel
                                        </button>
                                    </div>
                                <div className="form-group mb-3 col-md-3"></div>
                                </div>
                            </form>
                        :
                        <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['NAME']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Date"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['DATE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Ethnicity</label>
                                        <select
                                            defaultValue={"option"}
                                            id="EthnicityinputState"
                                            className="form-control"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['ETHINICITY']}
                                        >
                                            <option value="option" disabled>
                                            Choose Ethnicity...
                                            </option>
                                            <option value="Caucasian">Caucasian</option>
                                            <option value="African American">African American</option>
                                            <option value="Native American">Native American</option>
                                            <option value="Asian">Asian</option>
                                            <option value="Alaskan Native">Alaskan Native</option>
                                            <option value="Hawaiian">Hawaiian</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['ADDRESS']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['CITY']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>AL</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zipcode"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['ZIP CODE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Home"
                                        disabled={cardtitle === "View User" ? true : false}
                                        value={activeuser?.['PHONE - HOME']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Cellular"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['PHONE - CELLULAR']}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Property Size</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Property Size"
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['PROPERTY SIZE']}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Do you live in an unincorporated area in Lowndes County?</label>
                                            <div className="btn-group makebtnleft pt-1 col-md-3">
                                                <div className="form-check custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox1"
                                                    onClick={() => checkboxFun("yes")}
                                                    disabled={cardtitle === "View User" ? true : false}
                                                    checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="checkbox1"
                                                >Yes</label>
                                                </div>
                                                
                                            </div>
                                            <div className="btn-group pt-1 col-md-3">
                                            <div className="form-check custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox2"
                                                    onClick={() => checkboxFun("no")}
                                                    disabled={cardtitle === "View User" ? true : false}
                                                    checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="checkbox2"
                                                >No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                        <label>Type of residence?</label>
                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("House")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >House</label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-3">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("Mobile Home")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Mobile Home</label>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Water Source</label>
                                        <div className="btn-group pt-1 makebtnleft col-md-3">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("Public")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Public</label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-3">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("Private")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Private (Well)</label>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                        <label># of Occupants  </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="# of Occupants  "
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['OCCUPANTS']}
                                        />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                        <label># of Bedrooms </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="# of Bedrooms "
                                            disabled={cardtitle === "View User" ? true : false}
                                            value={activeuser?.['BEDROOMS']}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-12">
                                        <label>What is your current method of sewage disposal?</label>
                                        <div className="btn-group pt-1 col-md-12">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => checkboxFun("yes")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Straight pipe or septic system failure affecting a neighboring property.  </label>
                                            </div>
                                            
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("no")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                            </div>
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => checkboxFun("no")}
                                                disabled={cardtitle === "View User" ? true : false}
                                                checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-12">
                                        <label>Documents Needed</label>
                                        <div className="btn-group pt-1 col-md-12">
                                            <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox1"
                                                onClick={() => setfileUpload1checkbox(!fileUpload1checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox1"
                                            >Copy of Driver’s License/Non-Driver ID</label>
                                            </div>
                                            {fileUpload1checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false} />
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload2checkbox(!fileUpload2checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Proof of Land Ownership</label>
                                            </div>
                                            {fileUpload2checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload3checkbox(!fileUpload3checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Copy of Water Usage </label>
                                            </div>
                                            {fileUpload3checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                        <div className="btn-group pt-1 col-md-12">
                                        <div className="form-check custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="checkbox2"
                                                onClick={() => setfileUpload4checkbox(!fileUpload4checkbox)}
                                                disabled={cardtitle === "View User" ? true : false}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="checkbox2"
                                            >Proof of Income (example: Tax Return/SSI)</label>
                                            </div>
                                            {fileUpload4checkbox && 
                                                <div className="form-check custom-checkbox">
                                                    <input type="file" disabled={cardtitle === "View User" ? true : false}/>
                                                </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                            Save
                                        </button>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                            Cancel
                                        </button>
                                    </div>
                                <div className="form-group mb-3 col-md-3"></div>
                                </div>
                            </form>
                        }
                    </div>
                            :
                            <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>S NO.</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>ETHINITICTY</strong>
                    </th>
                    <th>
                      <strong>WATER SOURCE</strong>
                    </th>
                    <th>
                      <strong>CITY</strong>
                    </th>
                    <th>
                      <strong>Actions</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {usertable.map((item, i) => {
                    return [
                        <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item.NAME}</td>
                            <td>{item.ETHINITICTY}</td>
                            <td>{item['WATER SOURCE']}</td>
                            <td>{item.CITY}</td>
                            <td><div className="d-flex">
                        <Link
                          onClick={(e)=> viewuser(e,item)}
                          className="btn btn-warning shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div></td>
                        </tr>
                    ];
                })}
                </tbody>
              </Table>
              
                        }
                    
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}
