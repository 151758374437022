import React,{useState, Fragment} from 'react';
import PageTItle from "../../layouts/PageTitle";
import {Button, Modal, Carousel } from "react-bootstrap";
import "./gallery.css";
import big1 from '../../../images/gallery/render_plan_1/plan-1.jpg';
import big2 from '../../../images/gallery/render_plan_2/plan-2.jpg';
import big3 from '../../../images/gallery/render_plan_3/plan_3.jpg';
import big4 from '../../../images/gallery/render_plan_4/plan.jpg';
import big5 from '../../../images/gallery/render_plan_5/plan_5.jpg';
import big6 from '../../../images/gallery/render_plan_6/plan_6.jpg';
import big7 from '../../../images/gallery/render_plan_7/plan.jpg';

import big1image1 from '../../../images/gallery/render_plan_1/1.jpg';
import big1image2 from '../../../images/gallery/render_plan_1/2.jpg';
import big1image3 from '../../../images/gallery/render_plan_1/3.jpg';
import big1image4 from '../../../images/gallery/render_plan_1/4.jpg';
import big1image5 from '../../../images/gallery/render_plan_1/5.jpg';

import big2image1 from '../../../images/gallery/render_plan_2/1.jpg';
import big2image2 from '../../../images/gallery/render_plan_2/2.jpg';
import big2image3 from '../../../images/gallery/render_plan_2/3.jpg';

import big3image1 from '../../../images/gallery/render_plan_3/1.jpg';
import big3image2 from '../../../images/gallery/render_plan_3/2.jpg';
import big3image3 from '../../../images/gallery/render_plan_3/3.jpg';

import big4image1 from '../../../images/gallery/render_plan_4/1.jpg';
import big4image2 from '../../../images/gallery/render_plan_4/2.jpg';
import big4image3 from '../../../images/gallery/render_plan_4/3.jpg';

import big5image1 from '../../../images/gallery/render_plan_5/1.jpg';

import big6image1 from '../../../images/gallery/render_plan_6/1.jpg';

import big7image1 from '../../../images/gallery/render_plan_7/1.jpg';
import big7image2 from '../../../images/gallery/render_plan_7/2.jpg';


export default function Gallery() {
    const [modaltitle, setmodaltitle] = useState('')
    const [showhover, setshowhover] = useState(false)
    const [carousel, setcarousel] = useState([])

    function setdataformodal(title) {
        setmodaltitle(title)
        setshowhover(true)
        if(title === 'Facility 1') {
            setcarousel([big1image1, big1image2, big1image3,big1image4, big1image5, big1])
        }
        if(title === 'Facility 2') {
            setcarousel([big2image1, big2image2, big2image3, big2])
        }
        if(title === 'Facility 3') {
            setcarousel([big3image1, big3image2, big3image3, big3])
        }
        if(title === 'Facility 4') {
            setcarousel([big4image1, big4image2, big4image3, big4])
        }
        if(title === 'Facility 5') {
            setcarousel([big5image1, big5])
        }
        if(title === 'Facility 6') {
            setcarousel([big6image1, big6])
        }
        if(title === 'Facility 7') {
            setcarousel([big7image1,big7image2,big7])
        }
    }

	return(
        <Fragment>
            <PageTItle activeMenu="Design Layout" motherMenu="Design Layout" pageContent="Design Layout" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 1')}>
                            <h3>Facility 1</h3>
                            <img src={big1image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 2')}>
                            <h3>Facility 2</h3>
                            <img src={big2image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 3')}>
                            <h3>Facility 3</h3>
                            <img src={big3image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 4')}>
                            <h3>Facility 4</h3>
                            <img src={big4image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 5')}>
                            <h3>Facility 5</h3>
                            <img src={big5image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 6')}>
                            <h3>Facility 6</h3>
                            <img src={big6image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                        <div className="col-lg-12 cursor-pointer" onClick={() => setdataformodal('Facility 7')}>
                            <h3>Facility 7</h3>
                            <img src={big7image1} style={{width:"100%", height: "500px"}} alt="gallery"/>
                        </div>
                        <hr/>
                    </div>
                    <Modal className="fade bd-example-modal-lg" show={showhover} size="lg">
                        <Modal.Header>
                            <Modal.Title>{modaltitle}</Modal.Title>
                            <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setshowhover(false)}
                            >
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Carousel>
                                {carousel.map((item, i) => (
                                <Carousel.Item key={i}>
                                    <img
                                    src={item}
                                    className='d-block w-100'
                                    alt={`Slide ${i + 1}`}
                                    />
                                </Carousel.Item>
                                ))}
                            </Carousel>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                            onClick={() => setshowhover(false)}
                            variant="primary"
                            >
                            Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </Fragment>			
	)
}