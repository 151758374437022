import React from 'react';

function Newcomp() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card bg-warning invoice-card">
            {/* Card content */}
            <p>Test</p>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card invoice-card" style={{ backgroundColor: '#361ec2' }}>
          <p>Test</p>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card invoice-card" style={{ backgroundColor: '#12a76f' }}>
          <p>Test</p>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card invoice-card" style={{ backgroundColor: '#e13de8' }}>
          <p>Test</p>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-6 col-sm-6">
          <div className="card invoice-card" style={{ backgroundColor: '#ab826e' }}>
          <p>Test</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newcomp;
