import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import BBUWP from "../../../images/iwashwelllogowhite.png";
import QRPIC from "../../../images/qr.png";
export default function InvoicePrint({ PrintValues }) {



    useEffect(() => {


    }, [])


    return (
        <>

            <div>
                <table className="table col-md-12" style={{ width: '100%' }}>
                    <tr>
                        <td>
                            <span style={{ textAlign: 'left' }}>
                                <img style={{
                                }} src={BBUWP} alt="" width="100" height="100" />
                            </span>
                        </td>
                        <td><h1 style={{ textAlign: 'center' }}><strong><big>Invoice</big></strong></h1></td>
                        <td><h2 style={{ textAlign: 'center' }}><strong>BBUWP MEMBER WATER AND SANITATION SERVICE</strong></h2></td>
                        <td style={{ textAlign: 'right' }}>
                            <span>
                                <img style={{
                                }} src={QRPIC} alt="" width="100" height="100" />
                            </span>
                        </td>
                    </tr>
                </table>


                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center' }} colSpan={2}>
                                Black Belt Unincorporated Wastewater Progfram|527 Herron street | Montgomery, al | (334) 324-6430
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td style={{ width: '482px;height:133px;', border: '1px solid #dee2e6' }} >
                                <p>DATE:</p>

                                <p><span style={{ color: '#0000FF' }}><strong>[January 1 2024]&lt;-automatically generated)</strong></span></p>

                                <p>INVOICE #:</p>

                                <p><strong><span style={{ color: '#0000FF' }}>[2024-00001-]&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

                                <p>MEMBER ID#: &nbsp;<span style={{ color: '#0000FF' }}>[ XXXXXX&nbsp; ]</span>&nbsp;&nbsp;&nbsp; PROJECT id#:&nbsp; <span style={{ color: '#0000FF' }}>[ UNZ XXXX ]</span></p>

                                <p>&nbsp;</p>


                            </td>
                            <td style={{ border: '1px solid #dee2e6' }}>
                                <p>TO<span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [NAME]</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ADDRESS]</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [CITY/TOWN]</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ZIP CODE]</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '240px;height:33px;' }}>
                                <p>BBUWP CONTACT PERSON <span style={{ color: '#0000FF' }}>[Bridget]</span></p>
                            </td>
                            <td style={{ width: '204px;height:33px;' }}>
                                <p>&nbsp;CONTACT#&nbsp; <span style={{ color: '#0000FF' }}>[ 123-456-2341</span></p>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={2}><strong>Payment Terms</strong></td>
                            <td colSpan={2}><strong>Due Upon Receipt</strong></td>
                        </tr>
                    </tbody>
                </table>

                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <thead>
                        <tr>
                            <th scope="col">QTY</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">UNIT PRICE</th>
                            <th scope="col">LINE TOTAL</th>
                        </tr>
                        <tr>
                            <th scope="col">1</th>
                            <th scope="col">January Monthly Operations and Maintainence</th>
                            <th scope="col">$20</th>
                            <th scope="col">$20</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}></td>
                            <td>SUB TOTAL</td>
                            <td>$20</td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td>SALES TAX </td>
                            <td>$0</td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td><strong>TOTAL</strong></td>
                            <td>$20</td>
                        </tr>
                    </tbody>
                </table>


                <p style={{ textAlign: 'center' }}><strong>Make all checks payable to: BBUWP</strong></p>

                <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Bank]</strong></span></p>

                <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Deposit account #]</strong></span></p>

                <p>NOTICE:</p>

                <p>Add specific notifications here</p>

                <p>[ ] Keep Reccord of Payment Send Picture of Deposit Slip to cell number xxx-xxx-xxxx</p>
            </div>

        </>
    )
}
