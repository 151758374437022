import {Link} from 'react-router-dom';
import DropdownBlog from './../DropdownBlog';

const SpendingsBlog = () => {
	return(
		<>
			<div className="card">
				<div className="card-header border-0 pb-0">
					<div>
						<h4 className="card-title mb-2">Traditional</h4>
						<span className="fs-12"></span>
					</div>
					<DropdownBlog />
				</div>
				<div className="card-body">	
					<div className="progress default-progress">
						<div className="progress-bar bg-gradient-1 progress-animated" style={{width: "100%", height:"20px"}} role="progressbar">
							<span className="sr-only">45% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 pb-3 justify-content-between">
						<span>Total</span>
						<span className="fs-18"><span className="text-black pe-2">$13600</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-2 progress-animated" style={{width: "5%", height:"20px"}} role="progressbar">
							<span className="sr-only">70% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 pb-3 justify-content-between">
						<span>Ordered </span>
						<span className="fs-18"><span className="text-black pe-2">$400</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-3 progress-animated" style={{width: "25%", height:"20px"}} role="progressbar">
							<span className="sr-only">35% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 pb-3 justify-content-between">
						<span>In Stock</span>
						<span className="fs-18"><span className="text-black pe-2">$3400</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-4 progress-animated" style={{width: "50%", height:"20px"}} role="progressbar">
							<span className="sr-only">95% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 justify-content-between">
						<span>Under Contract </span>
						<span className="fs-18"><span className="text-black pe-2">$5300</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-5 progress-animated" style={{width: "30%", height:"20px"}} role="progressbar">
							<span className="sr-only">95% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 justify-content-between">
						<span>In Process </span>
						<span className="fs-18"><span className="text-black pe-2">$2500</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-6 progress-animated" style={{width: "25%", height:"20px"}} role="progressbar">
							<span className="sr-only">95% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 justify-content-between">
						<span>Installed  </span>
						<span className="fs-18"><span className="text-black pe-2">$1500</span></span>
					</div>
					<div className="progress default-progress mt-4">
						<div className="progress-bar bg-gradient-1 progress-animated" style={{width: "5%", height:"20px"}} role="progressbar">
							<span className="sr-only">75% Complete</span>
						</div>
					</div>
					<div className="d-flex align-items-end mt-2 justify-content-between">
						<span>Under O&M </span>
						<span className="fs-18"><span className="text-black pe-2">$500</span></span>
					</div>
				</div>
				{/* <div className="card-footer border-0 pt-0">
					<Link to={"#"} className="btn btn-outline-primary d-block btn-lg">View More</Link>
				</div> */}
			</div>

		</>	
	)
} 
export default SpendingsBlog;