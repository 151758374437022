import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const CardBlog = () => {
	const griditem = {
		padding: '10px',
		heigt: '15px'
	}
	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<div>
						<h4 className="card-title mb-4">Accounts Receivable</h4>
					</div>
					<section className='d-flex'>
						<div style={griditem}>
							<div className="card">
								<div className="card-body">
									<h4 className="card-title mt-2">Membership Fees/Payments received</h4>
									<div className="d-flex align-items-center mt-3 mb-2">
										<h2 className="fs-38 mb-0 me-3">$100</h2>
										{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
									</div>
								</div>
							</div>
						</div>
						<div style={griditem}>
							<div className="card">
								<div className="card-body">
									<h4 className="card-title mt-2">ARPA </h4>
									<div className="d-flex align-items-center mt-3 mb-2">
										<h2 className="fs-38 mb-0 me-3">$200</h2>
										{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
									</div>
								</div>
							</div>
						</div>
						<div style={griditem}>
							<div className="card">
								<div className="card-body">
									<h4 className="card-title mt-2">USDA </h4>
									<div className="d-flex align-items-center mt-3 mb-2">
										<h2 className="fs-38 mb-0 me-3">$100</h2>
										{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
									</div>
								</div>
							</div>
						</div>
						<div style={griditem}>
							<div className="card">
								<div className="card-body">
									<h4 className="card-title mt-2">CDC Foundation Grant </h4>
									<div className="d-flex align-items-center mt-3 mb-2">
										<h2 className="fs-38 mb-0 me-3">$500</h2>
										{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
									</div>
								</div>
							</div>
						</div>
						<div style={griditem}>
							<div className="card">
								<div className="card-body">
									<h4 className="card-title mt-2">Other </h4>
									<div className="d-flex align-items-center mt-3 mb-2">
										<h2 className="fs-38 mb-0 me-3">$100</h2>
										{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
									</div>
								</div>
							</div>
						</div>
					</section>
				</Grid >
				
			</Grid >
			<div className="row">
			<div>
						<h4 className="card-title mb-4">Accounts Payable</h4>
					</div>
				<div className="col-sm-4">
					<div className="card">
						<div className="card-body">
							<h4 className="card-title mt-2">System Suppliers </h4>
							<div className="d-flex align-items-center mt-3 mb-2">
								<h2 className="fs-38 mb-0 me-3">$100</h2>
								{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="card">
						<div className="card-body">
							<h4 className="card-title mt-2">Contractors</h4>
							<div className="d-flex align-items-center mt-3 mb-2">
								<h2 className="fs-38 mb-0 me-3">$200</h2>
								{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="card">
						<div className="card-body">
							<h4 className="card-title mt-2">Consultants</h4>
							<div className="d-flex align-items-center mt-3 mb-2">
								<h2 className="fs-38 mb-0 me-3">$300</h2>
								{/* <span className="badge badge-success badge-xl">+0.5%</span> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default CardBlog;