import React, { Fragment , useState  } from "react";
import PageTItle from "../../layouts/PageTitle";
import  data from "../../components/table/tableData.js";
import {
    Table,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'

export default function ContractorPayment() {
    const [paymentmethod,setpaymentmethod] = useState("Money Order");
    const [duemethod,setduemethod] = useState("Current Due");
    const [btntitle, setbtntitle] = useState("Add Project");
    const [cardtitle, setcardtitle] = useState("List of Project");
    const [Showform, setShowform] = useState(false);
    const [projecttable, setprojecttable] = useState(data.paymentcontractortable);
    const [activeuser, setactiveuser] = useState({});

    function checkboxFun(option) {
        console.log(option)
    }

    function cardbtn() {
        if (btntitle === 'Add Project' || btntitle === 'Edit Project' || btntitle === 'View Project') {
            setbtntitle("Back");
            setcardtitle("Add Project")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Project");
            setcardtitle("List of Project")
            setactiveuser({})
            setShowform(false)
        }
    }

    function viewuser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View Project")
        setactiveuser(user)
        setShowform(true)
    }

    function edituser(e,user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Edit Project")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e,user) {
        e.preventDefault()
        setactiveuser(user)
        notifyError();
    }

    function payment(e,user) {
        e.preventDefault()
        Swal.fire({
            title: '<strong>Payment</strong>',
            html:
                `Last Paid Amount : `+ user['PAID'] +`</b><br>` +
                `Pending Amount : `+ user['PENDING'] +`</b>` +
                '<input id="swal-input1" class="swal2-input" placeholder="Enter Remarks">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Enter Amount">',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
              'Pay',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              'Cancel',
            cancelButtonAriaLabel: 'Thumbs down'
        })
    }

    const notifyError = () => {
        toast.error("Project Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    return (
        <Fragment>
            {/* <PageTItle activeMenu="Add Project" motherMenu="Project" pageContent="Add Project" /> */}
            <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="col-xl-6 col-lg-6">
                            <h4 className="card-title">{cardtitle}</h4>
                        </div>
                        <div className="col-xl-6 col-lg-6 text-end">
                            {/* <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button> */}
                        </div>
                    </div>
                    <div className="card-body">
                        {Showform === true 
                            ?
<div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['FIRST NAME']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['LAST NAME']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['ADDRESS']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Zipcode</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Zipcode"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['ZIP CODE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['PHONE NO']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['EMAIL ID']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Last Paid Amount</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Paid Amount"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['LAST PAID AMOUNT'] || "20"}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Last Paid Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Last Paid Date"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['LAST PAID Date']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Balance Amount </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Balance Amount"
                                        disabled={true}
                                        value={activeuser?.['BALANCE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Choose Payment Method</label>
                                    <select
                                        defaultValue={"option"}
                                        id="ScopeworkinputState"
                                        className="form-control"
                                        onChange={(e) => setpaymentmethod(e.target.value)}
                                        value={activeuser?.['PAYMENT METHOD'] || paymentmethod}
                                        disabled={cardtitle === "View Project" ? true : false}
                                    >
                                        <option value="Money Order">Money Order</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Cash Reciept">Cash Reciept</option>
                                        <option value="Online Wire Transfer">Online Wire Transfer</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>How much do you want to pay</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['PAYMENT']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>When would you like to pay</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Date"
                                        disabled={cardtitle === "View Project" ? true : false}
                                        value={activeuser?.['DATE']}
                                    />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                    <label>Upload {paymentmethod}</label>
                                    <input
                                        type="file"
                                        className="makebtnleft"
                                        disabled={cardtitle === "View Project" ? true : false}
                                    />
                                    </div>
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                            Save
                                        </button>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <button type="submit" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                            Cancel
                                        </button>
                                    </div>
                                <div className="form-group mb-3 col-md-3"></div>
                                </div>
                            </form>
                    </div>
                            :
                            <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>S NO.</strong>
                    </th>
                    <th>
                      <strong>FIRST NAME</strong>
                    </th>
                    <th>
                      <strong>ADDRESS</strong>
                    </th>
                    <th>
                      <strong>ZIPCODE</strong>
                    </th>
                    <th>
                      <strong>LAST PAID AMOUNT</strong>
                    </th>
                    <th>
                      <strong>PAYMENT METHOD</strong>
                    </th>
                    <th>
                      <strong>Actions</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {projecttable.map((item, i) => {
                    return [
                        <tr key={i}>
                            <td>{i+1}</td>
                            <td>{item['FIRST NAME']}</td>
                            <td>{item['ADDRESS']}</td>
                            <td>{item['ZIP CODE']}</td>
                            <td>{item['LAST PAID AMOUNT']}</td>
                            <td>{item['PAYMENT METHOD']}</td>
                            <td><div className="d-flex">
                        <Link
                          onClick={(e)=> viewuser(e,item)}
                          className="btn btn-warning shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                        <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link>
                        <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div></td>
                        </tr>
                    ];
                })}
                </tbody>
              </Table>
              
                        }
                    
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
}
