import React, { Fragment } from "react";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import PageTItle from "../../layouts/PageTitle";
import ComplaintsTable from "../../components/table/ComplaintsTable"
import  data from "../../components/table/tableData.js";

export default function Complaint() {
    return (
        <Fragment>
            <PageTItle activeMenu="Complaint Log" motherMenu="Complaint" pageContent="Complaint Log" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Complaint Log</h4>
                    </div>
                    <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label>Select Facility Location</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Witsand</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Type of Complaint</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Tap Broke</option>
                                    <option>Toilet Leakage</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Priority</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>High</option>
                                    <option>Medium</option>
                                    <option>Low</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Upload</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Upload Pic/Video"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Assign To</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Employee</option>
                                    <option>Sub Contractor</option>
                                    <option>Contractor</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Status</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Open</option>
                                    <option>In Progress</option>
                                    <option>Closed</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-group mb-3 col-md-3">
                        </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-primary">
                                    Create
                                </button>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-warning">
                                    Cancel
                                </button>
                            </div>
                        <div className="form-group mb-3 col-md-3"></div>
                        </div>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">List of Complaints</h4>
                    </div>
                    <div className="card-body">
                        <Tab.Container defaultActiveKey="open">
                            <Nav as="ul" className="nav-pills mb-4 light">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="open">
                                        Open
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="closed">
                                        Closed
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="inprogress">
                                        In Progress
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="">
                                <Tab.Pane eventKey="open">
                                <ComplaintsTable tablename="List of Complaints" tabledata={data.listoforderTable} status="Open"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="closed">
                                <ComplaintsTable tablename="List of Complaints" tabledata={data.listoforderTable} status="Closed"/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="inprogress">
                                <ComplaintsTable tablename="List of Complaints" tabledata={data.listoforderTable} status="InProgress"/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
