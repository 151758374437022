import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//import BBUWP from "../../../images/BBUWP.png";
import BBUWP from "../../../images/iwashwelllogowhite.png";
export default function CommonPrint() {
    
    useEffect(() => {


    }, [])
    return (
        <>
            <div>
                <div className="row">
                    <table className="table col-md-12" style={{width:'100%' }}>
                        <tr>
                            <td>
                                <p style={{ textAlign: 'center', color: '#385623' }}>
                                    <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        Black Belt Unincorporated Wastewater Program
                                    </span>
                                    <br></br>
                                    <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        Voluntary Application for an Onsite Septic Tank Disposal System
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <table className="table col-md-12" style={{width:'100%',textAlign: 'center' }}>
                        <tr>
                            <td>
                                <p style={{ textAlign: 'center' }}>
                                    <span style={{ textAlign: 'center' }}>
                                        <img style={{
                                        }} src={BBUWP} alt="" width="100" height="100" />
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong><span style={{ color: '#ff0000' }}>Questions you may have about this program, please call (334) 324-6430</span></strong>
                            </td>
                        </tr>
                    </table>
                    <div>
                    </div>
                    <p><strong><span>APPLICATIONS CAN BE MAILED TO:</span></strong><strong><span>&nbsp; </span></strong><span>BBUWP P.O. Box 720, Hayneville, AL 36040 </span><strong><span>OR</span></strong><span> BBUWP Main Office, 527 Herron Street, Montgomery, AL</span><span>&nbsp; </span><span>36104</span></p>
                    
                </div>
            </div >
        </>
    )
}
