import axios from 'axios';

var URL = "http://138.197.145.194:7002/"

export const LoginToapp = (value) => {
  //  var options = {
  //  url: URL+'iwash_app/login/',
  //  method: 'POST',
  //    headers: {'Content-Type': 'application/json'}, data: value
  //  };
  //  return axios(options)
  return URL + 'iwash_app/login/';
};

export const addMember = (value) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'dl_proof' || key == 'income_proof' || key == 'land_own_proof' || key == 'water_usage_proof') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/membercreate/',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    // headers: {'Content-Type': 'application/json'}, data: value
    data: form
  };
  return axios(options)
};

export const updateMember = (value, id) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'dl_proof' || key == 'income_proof' || key == 'land_own_proof' || key == 'water_usage_proof' || key == 'signed_form') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/memberupdate/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    // headers: {'Content-Type': 'application/json'}, data: value
    data: form
  };
  return axios(options)
};

export const getMemberList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/memberlist/?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateMemberWFStatus = (value, id) => {
  let token = localStorage.getItem('token');
  const form = new FormData();
  form.append('status', value);
  form.append('member_id', id);
  var options = {
    url: URL + 'iwash_app/mem_work_flow_status_change/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    },
    data: form
  };
  return axios(options)
};
export const deleteMemberTrack = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/memberdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------------------------------------------------------------------------------

export const addPaymentTrack = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/pay_trackcreate/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const getPayHistoryList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/pay_tracklist/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getPayHistoryNewList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/member_payment_list/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getPayHistoryNewDetails = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/member_payment_details/?member_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updatePaymentTrack = (value, id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/member_update_payment/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const deletePaymentTrack = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/pay_trackdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const addContractor = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/contractorcreate/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const updateContractor = (value, id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/contractorupdate/' + id + '/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    },
    data: value
  };
  return axios(options)
};

export const getContractorList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/contractorlist/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const deleteContractor = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/contractordelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};


//--------------------------------------- Workflow Application -----------------------------------//
export const getWFApplicationList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/applicationlist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateWFApplication = (value, id) => {
  let token = localStorage.getItem('token');
  const form = new FormData();
  form.append('status', value);
  var options = {
    url: URL + 'iwash_app/applicationupdate/' + id + '/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    },
    data: form
  };
  return axios(options)
};
export const deleteWFApplication = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/applicationdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------- Workflow Soil -----------------------------------//
export const getWFSoilList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/soil_testlist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateWFSoil = (value, id) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'document') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/soil_testupdate/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    data: form
  };
  return axios(options)
};
export const deleteWFSoil = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/soil_testdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------- Workflow Eng -----------------------------------//
export const getWFEngineeringList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/engg_designlist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateWFEngineering = (value, id) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'revised_schematics') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/engg_designupdate/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    data: form
  };
  return axios(options)
};
export const deleteWFEngineering = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/engg_designdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------- Workflow Installation -----------------------------------//
export const getWFInstallationList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/installationlist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateWFInstallation = (value, id) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'cep_install_cert' || key == 'cep_engg_cert' || key == 'convenant' || key == 'others') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/installationupdate/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    data: form
  };
  return axios(options)
};
export const deleteWFInstallation = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/installationdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------- Workflow ElecAndPlum -----------------------------------//
export const getWFElecAndPlumList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/electrical_plumbinglist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateWFElecAndPlum = (value, id) => {
  const form = new FormData();

  for (const key in value) {
    if (key == 'elect_document' || key == 'plumb_document' || key == 'land_own_proof' || key == 'water_usage_proof') {
      if (value[key][0] != undefined)
        form.append(key, value[key][0]);
      else
        form.append(key, '');
    } else {
      form.append(key, value[key]);
    }

  }
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/electrical_plumbingupdate/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'token ' + token },
    data: form
  };
  return axios(options)
};
export const deleteWFElecAndPlum = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/electrical_plumbingdelete/' + id + '/',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//--------------------------------------- Workflow Summary -----------------------------------//
export const getWFSummaryList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/summarylist?status=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getWFSummaryDetails = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/summarydetails?member_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
//--------------------------------------- Workflow MemberHeader -----------------------------------//
export const getMemberHeaderList = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'iwash_app/headerlist?member_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};


//-----------------------------------------Status Change -----------------------------------------//
export const updateWFStatus = (value, id, stage) => {
  let token = localStorage.getItem('token');
  const form = new FormData();
  form.append('status', value);
  form.append('member_id', id);
  form.append('stage_name', stage);
  var options = {
    url: URL + 'iwash_app/work_flow_status_change/',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    },
    data: form
  };
  return axios(options)
};

//-----------------------------------------View Invoice------------------------------------------//
export const ViewInvoice = (value, id, stage) => {
  let token = localStorage.getItem('token');
  const form = new FormData();
  form.append('member_id', id);
  form.append('month', value);
  form.append('year', stage);
  var options = {
    url: URL + 'iwash_app/invoice/?member_id=' + id + '&month=' + value + '&year=' + stage,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    }
    //data: form
  };
  return axios(options)
};
//-----------------------------------------View Invoice------------------------------------------//
export const DownloadDocument = (value) => {
  let token = localStorage.getItem('token');
  const form = new FormData();
  form.append('file_id', value);
  let RawData = { 'file_id': value };
  var options = {
    url: URL + 'iwash_app/download/?file_id=' + value,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token,
      //  responseType: 'application/octet-stream'
    },
    responseType: 'blob',
    //data: RawData
  };
  return axios(options)
};