import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { ToastContainer, toast } from "react-toastify";


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history) {
    localStorage.removeItem('token');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}
export function CustomerInvoiceDownload(history) {
    localStorage.removeItem('token');
    //history.push('/CustomerInvoiceDownload/:MemberId/:Month/:Year');
    return {
        type: LOGOUT_ACTION,
    };
}
export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data[0].message == undefined) {
                    saveTokenInLocalStorage(response.data);
                    runLogoutTimer(
                        dispatch,
                        1000000 * 10000000,
                        history,
                    );
                    dispatch(loginConfirmedAction(response.data));
                    history.push('/dashboard');
                }
                else {
                    dispatch(loginFailedAction(response.data[0].message));
                    
                }


            })
            .catch((error) => {
                //console.log(error);
                //const errorMessage = formatError(error.response.data[0].message);
                toast.error(error.response.data[0].message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                dispatch(loginFailedAction(error.response.data[0].message));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
