import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import BBUWP from "../../../images/BBUWP.png";
const schema = yup.object().shape({
    first_name: yup.mixed(),
    last_name: yup.mixed(),
    address: yup.mixed(),
    city: yup.mixed(),
    state: yup.mixed(),
    AL: yup.mixed(),
    mobile_phone: yup.mixed(),
    elect_contractor: yup.string().required('contractor is required'),
    elect_assigned_date: yup.string().required('Assigned date is required'),
    elect_cost: yup.string().required('Cost is required'),
    plumb_contractor: yup.string().required('contractor is required'),
    plumb_assigned_date: yup.string().required('Assigned date is required'),
    plumb_cost: yup.string().required('Cost is required'),
    elect_document: yup.mixed(),
    elect_document: yup.mixed(),
    plumb_document: yup.string().required('Comments is required'),
    status: yup.string()
});
export default function HeaderPrint() {
    const [btntitle, setbtntitle] = useState("Add Electrical & Plumbing");
    const [cardtitle, setcardtitle] = useState("List of Electrical & Plumbing");
    const [Showform, setShowform] = useState(false);
    const [electricalplumbingtable, setelectricalplumbingtable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [usertable, setusertable] = useState([]);
    const [memberId, setMemberId] = useState("");

    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    function checkboxFun(option) {
        console.log(option)
    }
    useEffect(() => {


    }, [])
    const getMembers = () => {
        apiServices.getMemberList().then(response => {
            setusertable(response.data)
        }).catch(error => {

        })
    }
    const getWFElecAndPlumList = (sType) => {
        apiServices.getWFElecAndPlumList(sType).then(response => {
            setelectricalplumbingtable(response.data)
        }).catch(error => {

        })
    }
    const onSubmit = (e) => {
        apiServices.updateWFElecAndPlum(getValues(), activeuser.id).then(response => {
            // reset()
            notifySuccess(response.data[0].message);
            cardbtn()
            getWFElecAndPlumList('Pending');
        }).catch(error => {

        })

    };
    function cardbtn() {
        if (btntitle === 'Add Electrical & Plumbing' || btntitle === 'Edit Electrical & Plumbing' || btntitle === 'View Electrical & Plumbing') {
            setbtntitle("Back");
            setcardtitle("Add Electrical & Plumbing")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Electrical & Plumbing");
            setcardtitle("List of Electrical & Plumbing")
            setactiveuser({})
            setShowform(false)
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('elect_contractor', user.elect_contractor);
            setValue('elect_assigned_date', user.elect_assigned_date);
            setValue('comments', user.comments);
            setValue('elect_cost', user.elect_cost);
            setValue('plumb_cost', user.plumb_cost);
            setValue('plumb_contractor', user.plumb_contractor);
            setValue('plumb_assigned_date', user.plumb_assigned_date);
            // for (const key in response) {
            //     //setValue(key, value[key]);s
            // }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("View Electrical & Plumbing")
        setactiveuser(user)
        setShowform(true)
    }

    function edituser(e, user) {
        e.preventDefault()
        setMemberId(user.member_id)
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('elect_contractor', user.elect_contractor);
            setValue('elect_assigned_date', user.elect_assigned_date);
            setValue('comments', user.comments);
            setValue('elect_cost', user.elect_cost);
            setValue('plumb_cost', user.plumb_cost);
            setValue('plumb_contractor', user.plumb_contractor);
            setValue('plumb_assigned_date', user.plumb_assigned_date);
            // for (const key in response) {
            //     //setValue(key, value[key]);
            // }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("Edit Electrical & Plumbing")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e, user) {
        e.preventDefault()
        apiServices.deleteWFElecAndPlum(user.id).then(response => {
            cardbtn()
            getWFElecAndPlumList('Pending');
            notifyError();
            setbtntitle("Add Electrical & Plumbing");
            setcardtitle("List of Electrical & Plumbing")
            setactiveuser({})
            setShowform(false)
        }).catch(error => {
        });
        //setactiveuser(user)

    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyError = () => {
        toast.error("Electrical & Plumbing Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    function Print(elem) {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById('printablediv').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }
    // function Print() {
    //     //e.preventDefault()
    //     //console.log('print');  
    //     let printContents = document.getElementById('printablediv').innerHTML;
    //     let originalContents = document.body.innerHTML;
    //     document.body.innerHTML = printContents;
    //     window.print();
    //     document.body.innerHTML = originalContents;
    // }
    return (
        <>
            <div id="printablediv">
                <div className="row">
                    <table>
                        <tr>
                            <td>
                                <p style={{ textAlign: 'center', color: '#385623' }}>
                                    <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        Black Belt Unincorporated Wastewater Program
                                    </span>
                                    <br></br>
                                    <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        Voluntary Application for an Onsite Septic Tank Disposal System
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <table style={{ textAlign: 'center' }}>
                        <tr>
                            <td>
                                <p style={{ textAlign: 'center' }}>
                                    <span style={{ textAlign: 'center' }}>
                                        <img style={{
                                        }} src={BBUWP} alt="" width="160" height="64" />
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong><span style={{ color: '#ff0000' }}>Questions you may have about this program, please call (334) 324-6430</span></strong>
                            </td>
                        </tr>
                    </table>
                    <div>

                    </div>









                    <p><strong><span>APPLICATIONS CAN BE MAILED TO:</span></strong><strong><span>&nbsp; </span></strong><span>BBUWP P.O. Box 720, Hayneville, AL 36040 </span><strong><span>OR</span></strong><span> BBUWP Main Office, 527 Herron Street, Montgomery, AL</span><span>&nbsp; </span><span>36104</span></p>

                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontWeight: 'bold' }}>
                            STATE OF ALABAMA
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <br />______________________{' '}
                            <strong>COUNTY</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            I, a Notary Public, hereby certify that _______________________________________
                            whose name is signed to the foregoing instrument or conveyance, and who is known to me,
                            acknowledged before me on this day that, being informed of the contents of the
                            conveyance, he/she executed the same voluntarily on the day the same bears date.
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Given under my hand this _____________ day of _____________, A. D. 20____.
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            {' '}
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Notary Public:
                        </span>
                    </p>
                    <p align="center">
                        <span style={{ color: '#ffffff', fontFamily: 'Arial, serif', fontSize: 'medium' }}>
                            <strong>For Administrative Use Only</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Date Received: __________________ Local Health Department Permit #: _________________</span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}> Date Approved for Use Installed: __________________</span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Soil Professional&rsquo; Name: _________________________ </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Application Approved: <img width="18" height="16" name="Picture 92" align="bottom" border="0" /> </span>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'medium' }}> Yes <img width="18" height="16" name="Picture 5" align="bottom" border="0" /> No (see comments)</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Date Installed: _______________ Installer&rsquo;s Name: ___________________</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Documentation Received:</span>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}> <img width="18" height="16" name="Picture 6" align="bottom" border="0" /> CEP-5 Installer Certification <img width="18" height="16" name="Picture 7" align="bottom" border="0" /> CEP-6 Engineer Certification (if applicable) <img width="18" height="16" name="Picture 8" align="bottom" border="0" /> Covenant (if applicable)</span>
                    </p>
                    <br />
                    <br />
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}><strong>COMMENTS:</strong></span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}><strong>______________________________________________________________________________________________</strong></span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}><strong>______________________________________________________________________________________________</strong></span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}><strong>______________________________________________________________________________________________</strong></span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}><strong>______________________________________________________________________________________________</strong></span>
                    </p>
                </div>
                <button className="btn btn-success" onClick={Print}>
                    Print
                </button>
            </div >
        </>
    )
}
