import React, { Fragment } from "react";
import PageTItle from "../../layouts/PageTitle";
import FeesCollection from "../../components/table/FeesCollection"
import  data from "../../components/table/tableData.js";

export default function Employees() {
    return (
        <Fragment>
            <PageTItle activeMenu="Add Employee" motherMenu="Employee" pageContent="Add Employee" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Employee</h4>
                    </div>
                    <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                        <div className="form-group mb-3 col-md-6">
                            <label>First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Email</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>SSN</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="SSN"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Facility Location</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Facility Location"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Type of Work</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Cleaning</option>
                                    <option>Plumbing</option>
                                    <option>Security</option>
                                    <option>Manager</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Nature of Work</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Part Time</option>
                                    <option>Full Time</option>
                                </select>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                            <label>Hourly Wages</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Hourly Wages"
                            />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Status</label>
                                <select
                                    defaultValue={"option"}
                                    id="inputState"
                                    className="form-control"
                                >
                                    <option value="option" disabled>
                                    Choose...
                                    </option>
                                    <option>Active</option>
                                    <option>InActive</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-group mb-3 col-md-3">
                        </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-primary">
                                    Add
                                </button>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                                <button type="submit" className="btn btn-warning">
                                    Cancel
                                </button>
                            </div>
                        <div className="form-group mb-3 col-md-3"></div>
                        </div>
                        
                        </form>
                    </div>
                    </div>
                </div>
            </div>
            <FeesCollection tablename="List of Employees" tabledata={data.listofuserTable} />
        </Fragment>
    )
}
