import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import data from "../../components/table/tableData.js";
import {
    Nav,
    Table,
    Pagination,
    Tab
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import * as apiServices from '../../../services/apiServices'
import InvoicePrint from "../Application/InvoicePrint.js";
import BBUWP from "../../../images/iwashwelllogowhite.png";
import QRPIC from "../../../images/qr.png";
const schema = yup.object({
    member_id: yup.string(),
    first_name: yup.string(),
    last_name: yup.string(),
    member_name: yup.string(),
    payment_date: yup.string().required('Payment Date is required'),
    payment_method: yup.string().required('Payment Method is required'),
    payment_due_ids: yup.mixed(),
});

export default function MemberPayment() {
    const [paymentmethod, setpaymentmethod] = useState("Money Order");
    const [duemethod, setduemethod] = useState("Current Due");
    const [btntitle, setbtntitle] = useState("Add Project");
    const [cardtitle, setcardtitle] = useState("List of Project1");
    const [Showform, setShowform] = useState(false);
    const [projecttable, setprojecttable] = useState([]);
    const [paymentHistable, setpaymentHistable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [membersList, setMembersList] = useState([]);
    const [paymentTrackId, setPaymentTrackId] = useState('');
    const [payment_due_ids, setpayment_due_ids] = useState([]);
    const [applicationTab, setApplicationTab] = useState("Pending");
    const [invioceValue, setinvioceValue] = useState({});
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);


    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema)
    });
    const { register: registerForm1, handleSubmit: handleSubmitForm1, formState: { errors1 }, reset: reset1, setValue: setValue1, getValues: getValues1 } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        //getMembers()
        setbtntitle("Add Project");
        setcardtitle("List of Project")
        setactiveuser({})
        setShowform(false)
        getPayTrackHistory()
    }, [applicationTab])


    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase())
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if (typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if (page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
            currentPage = currentPage - 1;
            if (currentPage < 1) {
                currentPage = 1
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
            currentPage = currentPage + 1;
            if (currentPage > pages) {
                currentPage = pages;
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
            currentPage = 1
        } else if (page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
            currentPage = pages;
        } else {
            currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for (let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
            if (i <= pages)
                pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize);
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for (let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
            usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)
        let updatedState = {
            usersToBeShown: usersToBeShown,
            pageArray: pageArray,
            firstPage: firstPage,
            lastPage: lastPage,
            currentPage: currentPage,
            sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
    }

    const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
        calculatePaginationDetails(txt !== undefined ? txt : e.target.text, projecttable, currentPage);
        // }
    }

    const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
        // calculatePaginationDetails(1, projecttable, currentPage)
        setTimeout(() => {
            if (document.querySelector(".page-link"))
                document.querySelector(".page-link").click()
        }, 1000);
    }

    useEffect(() => {
        calculatePaginationDetails(1, projecttable, currentPage)
     }, [searchInput]);
    const handleCheckboxChange = (dueId) => {
        // Toggle the selected state of the payment_due_id
        let ids = payment_due_ids;
        if (payment_due_ids.includes(dueId)) {
            ids = ids.filter((id) => id !== dueId)
        } else {
            ids.push(dueId);
        }
        setpayment_due_ids(ids)
        // payment_due_ids.includes(dueId)
        //   ? setpayment_due_ids(payment_due_ids,dueId)
        //   : setpayment_due_ids(payment_due_ids,dueId)
        // setpayment_due_ids({
        //   updatedPaymentDueIds
        // });
    };

    const onSubmit = e => {
        //e.preventDefault()
        //console.log(getValues())
        if (cardtitle === "Add Project") {
            apiServices.addPaymentTrack(getValues()).then(response => {
                console.log(response)
                reset()
            }).catch(error => {

            })
        }
        else {
            if (payment_due_ids.length == 0) {
                notifyError('Please select payment due');
                return false;
            }
            setValue('payment_due_ids', payment_due_ids);
            apiServices.updatePaymentTrack(getValues(), paymentTrackId).then(response => {
                toast.success(response.data[0].message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getPayTrackHistory();
                cardbtn();
            }).catch(error => {

            })
        }

    };

    const getPayTrackHistory = () => {
        apiServices.getPayHistoryNewList().then(response => {
            setprojecttable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)

        }).catch(error => {

        })
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        return formattedDate;
    };

    const getMembers = () => {
        apiServices.getMemberList().then(response => {
            setMembersList(response.data)
        }).catch(error => {

        })
    }

    function checkboxFun(option) {
        console.log(option)
    }

    function cardbtn() {
        if (btntitle === 'Add Project' || btntitle === 'Edit Project' || btntitle === 'View Project') {
            setbtntitle("Back");
            setcardtitle("Add Project")
            setactiveuser({})
            setShowform(true)
            reset()
        } else {
            setbtntitle("Add Project");
            setcardtitle("List of Project")
            setactiveuser({})
            setShowform(false)
            getPayTrackHistory()
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        // setbtntitle("Back");
        // setcardtitle("View Project")
        // setactiveuser(user)
        // setShowform(true)
        // setValue('member_id', user.member_id)
        // setValue('project_name', user.project_name)
        // setValue('last_paid_amount', user.last_paid_amount)
        // setValue('current_due_amount', user.current_due_amount)
        // setValue('over_due_amount', user.over_due_amount)
        // setValue('last_paid_date', user.last_paid_date)
        // setValue('current_due_date', user.current_due_date)
        // //setValue('over_due_date', user.over_due_date)
        // setValue('payment_date', user.payment_date)
        // setValue('payment_method_others', user.payment_method_others)
        apiServices.ViewInvoice(user.month, user.member_id, user.year).then(response => {

            setinvioceValue(response.data[0]);
            Print('');
        }).catch(error => {

        })

    }
    function edituser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("Update Member Payment Details")
        setactiveuser({})
        setShowform(true)
        setPaymentTrackId(user.member_id)
        apiServices.getPayHistoryNewDetails(user.member_id).then(response => {
            //console.log(response)
            //setprojecttable(response.data)
            setValue('member_id', user.member_id)
            setValue('first_name', user.first_name)
            setValue('last_name', user.last_name)
            setValue('member_name', user.member_name)

            setValue1('member_id', user.member_id)
            setValue1('first_name', user.first_name)
            setValue1('last_name', user.last_name)
            setValue1('member_name', user.member_name)
            if (applicationTab == "Pending")
                setpaymentHistable(response.data.payment_details.filter(s => s.payment_date == '' || s.payment_date == null));
            else
                setpaymentHistable(response.data.payment_details.filter(s => s.payment_date != '' && s.payment_date != null));
        }).catch(error => {

        })

    }
    function ConvertDate(user) {
        const day = '1';
        const month = user.month;
        const year = user.year;

        // Add leading zeros if needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    }
    function deleteuser(e, user) {
        e.preventDefault()
        setactiveuser(user)
        apiServices.deletePaymentTrack(user.id).then(response => {
            notifyError();
            getMembers()
            getPayTrackHistory()
        }).catch(error => {

        })
    }

    function payment(e, user) {
        e.preventDefault()
        Swal.fire({
            title: '<strong>Payment</strong>',
            html:
                `Last Paid Amount : ` + user['PAID'] + `</b><br>` +
                `Pending Amount : ` + user['PENDING'] + `</b>` +
                '<input id="swal-input1" class="swal2-input" placeholder="Enter Remarks">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Enter Amount">',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                'Pay',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
                'Cancel',
            cancelButtonAriaLabel: 'Thumbs down'
        })
    }

    const notifyError = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    function Print(elem) {
        var mywindow = window.open('Iwashwell', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');

        mywindow.document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="./css/style.css">';

        mywindow.document.write(document.getElementById('printableMemberPaymentdiv').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }
    return (
        <Fragment>
            {/* <PageTItle activeMenu="Add Project" motherMenu="Project" pageContent="Add Project" /> */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div style={{ display: 'none' }} className="pdf-page" id="printableMemberPaymentdiv">
                <table className="table col-md-12" style={{ width: '100%' }}>
                    <tr>
                        <td>
                            <span style={{ textAlign: 'left' }}>
                                <img style={{
                                }} src={BBUWP} alt="" width="100" height="100" />
                            </span>
                        </td>
                        <td><h1 style={{ textAlign: 'center' }}><strong><big>Invoice</big></strong></h1></td>
                        <td style={{ textAlign: 'right' }}>
                            <span>
                                <img style={{
                                }} src={QRPIC} alt="" width="100" height="100" />
                            </span>
                        </td>
                    </tr>
                </table>


                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center' }} colSpan={2}>
                                Black Belt Unincorporated Wastewater Progfram|527 Herron street | Montgomery, al | (334) 324-6430
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td style={{ width: '482px;height:133px;', border: '1px solid #dee2e6' }} >
                                <p>DATE:</p>

                                <p><span style={{ color: '#0000FF' }}><strong>{invioceValue.year}&lt;-automatically generated)</strong></span></p>

                                <p>INVOICE #:</p>

                                <p><strong><span style={{ color: '#0000FF' }}>{invioceValue.member_invoice_no}&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

                                <p>CUSTOMER ID#: &nbsp;<span style={{ color: '#0000FF' }}>[ XXXXXX&nbsp; ]</span>&nbsp;&nbsp;&nbsp; PROJECT id#:&nbsp; <span style={{ color: '#0000FF' }}>[ UNZ XXXX ]</span></p>

                                <p>&nbsp;</p>


                            </td>
                            <td style={{ border: '1px solid #dee2e6' }}>
                                <p>TO<span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {invioceValue.AL}</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ADDRESS]</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [CITY/TOWN]</span></p>

                                <p><span style={{ color: '#0000FF' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [ZIP CODE]</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '240px;height:33px;' }}>
                                <p>BBUWP CONTACT PERSON <span style={{ color: '#0000FF' }}>[Bridget]</span></p>
                            </td>
                            <td style={{ width: '204px;height:33px;' }}>
                                <p>&nbsp;CONTACT#&nbsp; <span style={{ color: '#0000FF' }}>[ 123-456-2341</span></p>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={2}><strong>Payment Terms</strong></td>
                            <td colSpan={2}><strong>Due Upon Receipt</strong></td>
                        </tr>
                    </tbody>
                </table>

                <table className="table col-md-12" style={{ border: '1px solid #dee2e6', width: '100%' }}>
                    <thead>
                        <tr>
                            <th scope="col">QTY</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">COST</th>
                        </tr>
                        <tr>
                            <th scope="col">1</th>
                            <th scope="col">January Monthly Operations and Maintainence</th>
                            <th scope="col">$20</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2}></td>
                            <td><strong>TOTAL</strong></td>
                            <td>$20</td>
                        </tr>
                    </tbody>
                </table>


                <p style={{ textAlign: 'center' }}><strong>Make all checks payable to: BBUWP</strong></p>

                <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Bank]</strong></span></p>

                <p style={{ textAlign: 'center' }}><span style={{ color: '#800080' }}><strong>[Deposit account #]</strong></span></p>

                <p>NOTICE:</p>

                <p>Add specific notifications here</p>

                <p>[ ] Keep Reccord of Payment Send Picture of Deposit Slip to cell number xxx-xxx-xxxx</p>
            </div>
            <div>
                <div>
                    <div className="basic-form">
                        {/* <InvoicePrint PrintValues={invioceValue}></InvoicePrint> */}
                    </div>
                </div>
            </div>
            <div className="default-tab">
                <Tab.Container defaultActiveKey={applicationTab} activeKey={applicationTab}>
                    <Nav as="ul" className="nav-tabs">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="Pending" onClick={() => { setApplicationTab('Pending'); SetSearchInput(""); }}>
                                <i className={`flaticon-381-windows me-2`} />
                                Payment
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="Approved" onClick={() => { setApplicationTab('Approved'); SetSearchInput(""); }}>
                                <i className={`flaticon-381-windows me-2`} />
                                Receipt
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="pt-4">
                        <Tab.Pane eventKey="Pending">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="col-xl-6 col-lg-6">
                                            <h4 className="card-title">{cardtitle}</h4>
                                        </div>
                                        {/* <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        {Showform === true
                                            ?
                                            <div className="basic-form">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Member Name</label>
                                                            <input
                                                                {...register("member_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Current Due Amount"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            // value={activeuser?.['CURRENT DUE AMOUNT'] || "20"}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>First Name</label>
                                                            <input
                                                                {...register("first_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            // value={activeuser?.['PROJECT NAME']}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Last Name</label>
                                                            <input
                                                                {...register("last_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            //value={activeuser?.['LAST PAID AMOUNT'] || "20"}
                                                            />
                                                        </div>

                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Payment Date</label>
                                                            <input
                                                                {...register("payment_date")}
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="Current Due Date"
                                                            />
                                                            {errors.payment_date && <small id="emailHelp" class="text-danger">{errors.payment_date?.message}</small>}
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Choose Payment Method</label>
                                                            <select
                                                                {...register("payment_method")}
                                                                defaultValue={"option"}
                                                                id="ScopeworkinputState"
                                                                className="form-control"
                                                            >
                                                                <option value="Money Order">Money Order</option>
                                                                <option value="Cheque">Cheque</option>
                                                                <option value="Cash Reciept">Cash Reciept</option>
                                                                <option value="Online Wire Transfer">Online Wire Transfer</option>
                                                                <option value="Others">Others</option>
                                                            </select>
                                                            {errors.payment_method_others && <small id="emailHelp" class="text-danger">{errors.payment_method_others?.message}</small>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {paymentHistable.length > 0 &&
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>#</strong>
                                                                        </th>
                                                                        <th>
                                                                            <strong>DATE</strong>
                                                                        </th>
                                                                        <th>AMOUNT</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {paymentHistable.map((item, i) => {
                                                                        return [
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            //checked={formData.payment_due_ids.includes(dueId)}
                                                                                            onChange={() => handleCheckboxChange(item.id)}
                                                                                        />
                                                                                    </label></td>
                                                                                <td>{formatDate(ConvertDate(item))}</td>
                                                                                <td>$20</td>
                                                                            </tr>
                                                                        ];
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-3">
                                                        </div>
                                                        {cardtitle !== "View User" &&
                                                            <>
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <button className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }

                                                        <div className="form-group mb-3 col-md-3"></div>
                                                    </div>
                                                </form>
                                            </div>
                                            :
                                            <>
                                                <div className="basic-form">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <Table responsive className="table table-striped">

                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>S NO.</strong>
                                                            </th>
                                                            <th>
                                                                <strong>NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>FIRST NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>LAST NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Actions</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {FilteredData().map((item, i) => {
                                                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                                            return [
                                                                <tr key={i}>
                                                                    <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                                    <td>{item['member_name']}</td>
                                                                    <td>{item['first_name']}</td>
                                                                    <td>{item['last_name']}</td>
                                                                    <td><div className="d-flex">
                                                                        {/* <Link
                                                        onClick={(e) => viewuser(e, item)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link> */}
                                                                        <Link
                                                                            onClick={(e) => edituser(e, item)}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        {/* <Link
                                                        onClick={(e) => deleteuser(e, item)}
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link> */}
                                                                    </div></td>
                                                                </tr>
                                                            ];
                                                        })}
                                                    </tbody>
                                                </Table>
                                                <br />
                                                {FilteredData().length > 0 &&
                                                    <Pagination>
                                                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                                            pageArray && pageArray.length &&
                                                            pageArray.map(
                                                                (item) => (
                                                                    <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                                        active={currentPage == item}>{item}</Pagination.Item>
                                                                )
                                                            )
                                                        }
                                                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                                    </Pagination>
                                                }
                                            </>

                                        }

                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Approved">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="col-xl-6 col-lg-6">
                                            <h4 className="card-title">{cardtitle}</h4>
                                        </div>
                                        {/* <div className="col-xl-6 col-lg-6 text-end">
                            <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                {btntitle}
                            </button>
                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        {Showform === true
                                            ?
                                            <div className="basic-form">
                                                <form>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Member Name</label>
                                                            <input
                                                                {...registerForm1("member_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Current Due Amount"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            //value={activeuser?.['CURRENT DUE AMOUNT'] || "20"}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>First Name</label>
                                                            <input
                                                                {...registerForm1("first_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="First Name"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            // value={activeuser?.['PROJECT NAME']}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Last Name</label>
                                                            <input
                                                                {...registerForm1("last_name")}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Last Name"
                                                                disabled={true}
                                                            // disabled={cardtitle === "View Project" ? true : false}
                                                            //value={activeuser?.['LAST PAID AMOUNT'] || "20"}
                                                            />
                                                        </div>

                                                        {/* <div className="form-group mb-3 col-md-4">
                                                        <label>Payment Date</label>
                                                        <input
                                                            {...registerForm1("payment_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Current Due Date"
                                                        />
                                                        {errors.payment_date && <small id="emailHelp" class="text-danger">{errors.payment_date?.message}</small>}
                                                    </div> */}
                                                        {/* <div className="form-group mb-3 col-md-4">
                                                        <label>Choose Payment Method</label>
                                                        <select
                                                            {...registerForm1("payment_method")}
                                                            defaultValue={"option"}
                                                            id="ScopeworkinputState"
                                                            className="form-control"
                                                        >
                                                            <option value="Money Order">Money Order</option>
                                                            <option value="Cheque">Cheque</option>
                                                            <option value="Cash Reciept">Cash Reciept</option>
                                                            <option value="Online Wire Transfer">Online Wire Transfer</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        {errors.payment_method_others && <small id="emailHelp" class="text-danger">{errors.payment_method_others?.message}</small>}
                                                    </div> */}
                                                    </div>
                                                    <div className="row">
                                                        {paymentHistable.length > 0 &&
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>DATE</strong>
                                                                        </th>
                                                                        <th>AMOUNT</th>
                                                                        <th>PAYMENT DATE</th>
                                                                        <th>PAYMENT METHOD</th>
                                                                        <th>ACTION</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {paymentHistable.map((item, i) => {
                                                                        return [
                                                                            <tr key={i}>
                                                                                <td>{formatDate(ConvertDate(item))}</td>
                                                                                <td>$20</td>
                                                                                <td>{item.payment_date}</td>
                                                                                <td>{item.payment_method}</td>
                                                                                <td>
                                                                                    <Link
                                                                                        onClick={(e) => viewuser(e, item)}
                                                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        ];
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        }
                                                    </div>
                                                    <div className="row" style={{float : "right", marin: "1px"}}>
                                                        {/* <div className="form-group mb-3 col-md-3">
                                                        </div> */}
                                                        {cardtitle !== "View User" &&
                                                            <>
                                                                {/* <div className="form-group mb-3 col-md-3">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Save
                                                                </button>
                                                            </div> */}
                                                                <div className="form-group mb-3 col-md-3">
                                                                    <button className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }

                                                        {/* <div className="form-group mb-3 col-md-3"></div> */}
                                                    </div>
                                                </form>
                                            </div>
                                            :
                                            <>
                                                <div className="basic-form">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <Table responsive className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>S NO.</strong>
                                                            </th>
                                                            <th>
                                                                <strong>NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>FIRST NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>LAST NAME</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Actions</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {FilteredData().map((item, i) => {
                                                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                                            return [
                                                                <tr key={i}>
                                                                    <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                                    <td>{item['member_name']}</td>
                                                                    <td>{item['first_name']}</td>
                                                                    <td>{item['last_name']}</td>
                                                                    <td><div className="d-flex">
                                                                        {/* <Link
                                                        onClick={(e) => viewuser(e, item)}
                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link> */}
                                                                        <Link
                                                                            onClick={(e) => edituser(e, item)}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        {/* <Link
                                                        onClick={(e) => deleteuser(e, item)}
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link> */}
                                                                    </div></td>
                                                                </tr>
                                                            ];
                                                        })}
                                                    </tbody>
                                                </Table>
                                                <br />
                                                {FilteredData().length > 0 &&
                                                    <Pagination>
                                                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                                            pageArray && pageArray.length &&
                                                            pageArray.map(
                                                                (item) => (
                                                                    <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                                        active={currentPage == item}>{item}</Pagination.Item>
                                                                )
                                                            )
                                                        }
                                                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                                    </Pagination>
                                                }
                                            </>

                                        }

                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Fragment>
    )
}
