import React, { Fragment, useState, useEffect } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import {
    Table,
    Pagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Soiltest from './Soiltest'
import EngineeringDesign from './EngineeringDesign'
import Installation from './Installation'
import ElectricalPlumbing from './ElectricalPlumbing'
import Summary from './Summary'
import * as apiServices from '../../../services/apiServices'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import HeaderPrint from "./HeaderPrint";
import InvoicePrint from "./InvoicePrint";
import swal from "sweetalert";
import Loader from "../../layouts/nav/Loader.js";
// import '../Application/Print.css';
const schema = yup.object().shape({
    first_name: yup.mixed(),
    last_name: yup.mixed(),
    address: yup.mixed(),
    city: yup.mixed(),
    state: yup.mixed(),
    AL: yup.mixed(),
    mobile_phone: yup.mixed()
});
export default function Application() {
    const [Sponsor, setSponsor] = useState("ARPA");
    const [fileUpload1checkbox, setfileUpload1checkbox] = useState(false);
    const [fileUpload2checkbox, setfileUpload2checkbox] = useState(false);
    const [fileUpload3checkbox, setfileUpload3checkbox] = useState(false);
    const [fileUpload4checkbox, setfileUpload4checkbox] = useState(false);
    const [applicationtable, setapplicationtable] = useState([]);
    const [btntitle, setbtntitle] = useState("Add Application");
    const [cardtitle, setcardtitle] = useState("List of Application");
    const [Showform, setShowform] = useState(false);
    const [activeuser, setactiveuser] = useState({});
    const [memberId, setMemberId] = useState("");
    const [activeTab, setActiveTab] = useState("application");
    const [applicationTab, setApplicationTab] = useState("Pending");
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [PrintValues, setPrintValues] = useState({});
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
    });
    function checkboxFun(option) {
        console.log(option)
    }
    useEffect(() => {
        if (activeTab == 'application') {
            setbtntitle("Add Application");
            setcardtitle("List of Application")
            setactiveuser({})
            setShowform(false)
            getWFApplicationList('Pending');
            setApplicationTab('Pending')
        }
    }, [activeTab])

    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase())
        );
    };

    const calculatePaginationDetails = (page, data, currPage) => {
        if (typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase())
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if (page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
            currentPage = currentPage - 1;
            if (currentPage < 1) {
                currentPage = 1
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
            currentPage = currentPage + 1;
            if (currentPage > pages) {
                currentPage = pages;
            }
        } else if (page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
            currentPage = 1
        } else if (page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
            currentPage = pages;
        } else {
            currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for (let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
            if (i <= pages)
                pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize);
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for (let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
            usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)
        let updatedState = {
            usersToBeShown: usersToBeShown,
            pageArray: pageArray,
            firstPage: firstPage,
            lastPage: lastPage,
            currentPage: currentPage,
            sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
    }

    const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
        calculatePaginationDetails(txt !== undefined ? txt : e.target.text, applicationtable, currentPage);
        // }
    }

    const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
       // calculatePaginationDetails(1, applicationtable, currentPage)
        setTimeout(() => {
            if (document.querySelector(".page-link"))
                document.querySelector(".page-link").click()
        }, 1000);
    }
    useEffect(() => {
        calculatePaginationDetails(1, applicationtable, currentPage)
     }, [searchInput, applicationtable]);

    const getWFApplicationList = (sType) => {
        setapplicationtable([])
        setIsLoading(true);
        apiServices.getWFApplicationList(sType).then(response => {
            setapplicationtable(response.data)
          
            setIsLoading(false);
            // calculatePaginationDetails(1, response.data, currentPage)
        }).catch(error => {
            setIsLoading(false);
        })
    }
    // const getMemberHeaderList = (member_id) => {
    //     apiServices.getMemberHeaderList(member_id).then(response => {
    //         setapplicationtable(response.data)
    //     }).catch(error => {

    //     })
    // }
    const onSubmit = (sType) => {
        var message;
        if (sType == "Approved") {
            message = "Approve";
        } else {
            message = "Reject";
        }
        swal({
            title: message,
            text:
                "Are you sure you want to " + message + "?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setIsLoading(true);
                apiServices.updateWFStatus(sType, memberId, 'application').then(response => {
                    setIsLoading(false);
                    notifySuccess(response.data[0].message);
                    cardbtn()
                    getWFApplicationList('Pending');

                }).catch(error => {
                    setIsLoading(false);
                });
            } else {
                //   swal("Your imaginary file is safe!");
            }
        })

    };
    function Print(elem) {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById('printablediv').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }
    // function print(){
    //     window.print();
    // }
    function PrintElem() {
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    function cardbtn() {
        if (btntitle === 'Add Application' || btntitle === 'Edit Application' || btntitle === 'View Application') {
            setbtntitle("Back");
            setcardtitle("Add Application")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Application");
            setcardtitle("List of Application")
            setactiveuser({})
            setShowform(false)
            getWFApplicationList("Pending")
        }
    }

    function viewuser(e, user) {
        e.preventDefault()
        setbtntitle("Back");
        setcardtitle("View Application")
        setactiveuser(user)
        setShowform(true)
        setMemberId(user.member_id)
        //getMemberHeaderList(user.member_id);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setValue('member_name', user.member_name);
            // setValue('contractor', user.contractor);
            // setValue('assigned_date', user.assigned_date);
            // setValue('comments', user.comments);
            // setValue('cost', user.cost);
            // for (const key in response) {
            //     //setValue(key, value[key]);
            // }
        }).catch(error => {

        })
    }

    function edituser(e, user) {
        e.preventDefault()
        setMemberId(user.member_id)
        //getMemberHeaderList(user.member_id);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('member_name', response.data[0].member_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            // setValue('contractor', user.contractor);
            // setValue('assigned_date', user.assigned_date);
            // setValue('comments', user.comments);
            // setValue('cost', user.cost);
            // for (const key in response) {
            //     //setValue(key, value[key]);
            // }
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("Edit Application")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e, user, sType) {
        e.preventDefault()
        //setactiveuser(user)
        swal({
            title: "Are you sure you want to delete?",
            text:
                "Once Deleted, it will be removed from Workflow. It needs to approved from Member to be processed in future. ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                apiServices.deleteWFApplication(user.id).then(response => {
                    cardbtn()
                    getWFApplicationList(sType);
                    notifyError();
                    setbtntitle("Add Application");
                    setcardtitle("List of Application")
                    setactiveuser({})
                    setShowform(false)
                }).catch(error => {
                });
            } else {
                //   swal("Your imaginary file is safe!");
            }
        })


    }
    const notifySuccess = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const notifyError = () => {
        toast.error("Application Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (

        <Fragment>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading ?
                <Loader />
                :
                <>
                    <PageTItle activeMenu="Application" motherMenu="Application" pageContent="Application" />

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title></Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="default-tab">
                                        <Tab.Container defaultActiveKey="application">
                                            <Nav as="ul" className="nav-tabs">
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="application" onClick={() => {setActiveTab("application"); SetSearchInput("");}}>
                                                        <i className={`flaticon-381-windows me-2`} />
                                                        Application
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="soiltest" onClick={() => {setActiveTab("soiltest"); SetSearchInput("");}}>
                                                        <i className={`flaticon-381-location me-2`} />
                                                        Soil Test
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="engineeringdesign" onClick={() => {setActiveTab("engineeringdesign"); SetSearchInput("");}}>
                                                        <i className={`flaticon-381-idea me-2`} />
                                                        Engineering Design
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="installation" onClick={() => {setActiveTab("installation"); SetSearchInput("");}}>
                                                        <i className={`flaticon-381-hourglass me-2`} />
                                                        Installation
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="electricalplumbing" onClick={() => setActiveTab("electricalplumbing")}>
                                                        <i className={`flaticon-381-battery me-2`} />
                                                        Electrical & Plumbing
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="summary" onClick={() => setActiveTab("summary")}>
                                                        <i className={`flaticon-381-file me-2`} />
                                                        Summary
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item as="li">
                                                    <Nav.Link eventKey="print" onClick={() => setActiveTab("print")}>
                                                        <i className={`flaticon-381-file me-2`} />
                                                        Print
                                                    </Nav.Link>
                                                </Nav.Item> */}
                                            </Nav>
                                            <Tab.Content className="pt-4">
                                                <Tab.Pane eventKey="application" >
                                                    <Tab.Container className='' defaultActiveKey="Pending" activeKey={applicationTab}>
                                                        <Nav as="ul" className="nav-tabs">
                                                            <Nav.Item as="li">
                                                                <Nav.Link eventKey="Pending" disabled={applicationTab == 'Pending' ? true : false} onClick={() => { getWFApplicationList('Pending'); setApplicationTab('Pending');SetSearchInput(""); }}>
                                                                    <i className={`flaticon-381-windows me-2`} />
                                                                    Pending
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as="li">
                                                                <Nav.Link eventKey="Rejected" disabled={applicationTab == 'Rejected' ? true : false} onClick={() => { getWFApplicationList('Rejected'); setApplicationTab('Rejected');SetSearchInput(""); }}>
                                                                    <i className={`flaticon-381-windows me-2`} />
                                                                    Rejected
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <Tab.Content className="pt-4">
                                                            <Tab.Pane eventKey="Pending">

                                                                <div className="row">
                                                                    {Showform === true
                                                                        ?
                                                                        <div className="basic-form">
                                                                            {/* <div className="row">
                                                                        <div className="form-group mb-3 col-md-3">
                                                                            <label>Sponsor</label>
                                                                            <select
                                                                                defaultValue={"option"}
                                                                                id="SponsorinputState"
                                                                                className="form-control"
                                                                                value={activeuser?.['SPONSORS'] || Sponsor}
                                                                                onChange={(e) => setSponsor(e.target.value)}
                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                            >
                                                                                <option value="ARPA">ARPA</option>
                                                                                <option value="USDA">USDA</option>
                                                                            </select>
                                                                        </div>
                                                                    </div> */}

                                                                            <form id="printablediv">
                                                                                <div className="row">
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>First Name</label>
                                                                                        <input
                                                                                            {...register("first_name")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Firstname"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['FIRST NAME']}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>Last Name</label>
                                                                                        <input
                                                                                            {...register("last_name")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Lastname"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['LAST NAME']}
                                                                                        />
                                                                                    </div>

                                                                                
                                                                                    {/* <div className="form-group mb-3 col-md-4">
                                                                                <label>Date</label>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    placeholder="Date"
                                                                                    disabled={cardtitle === "View Application" ? true : false}
                                                                                    value={activeuser?.['DATE']}
                                                                                />
                                                                            </div> */}
                                                                                    {/* <div className="form-group mb-3 col-md-4">
                                                                                <label>Ethnicity</label>
                                                                                <select
                                                                                    defaultValue={"option"}
                                                                                    id="EthnicityinputState"
                                                                                    className="form-control"
                                                                                    disabled={cardtitle === "View Application" ? true : false}
                                                                                    value={activeuser?.['ETHINICITY']}
                                                                                >
                                                                                    <option value="option" disabled>
                                                                                        Choose Ethnicity...
                                                                                    </option>
                                                                                    <option value="Caucasian">Caucasian</option>
                                                                                    <option value="African American">African American</option>
                                                                                    <option value="Native American">Native American</option>
                                                                                    <option value="Asian">Asian</option>
                                                                                    <option value="Alaskan Native">Alaskan Native</option>
                                                                                    <option value="Hawaiian">Hawaiian</option>
                                                                                    <option value="Other">Other</option>
                                                                                </select>
                                                                            </div> */}
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>Address</label>
                                                                                        <input
                                                                                            {...register("address")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Address"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['ADDRESS']}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>City</label>
                                                                                        <input
                                                                                            {...register("city")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="City"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['CITY']}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>Zip Code</label>
                                                                                        <input
                                                                                            {...register("AL")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Zipcode"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['ZIP CODE']}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <div className="form-group mb-3 col-md-4">
                                                                                        <label>Home Phone</label>
                                                                                        <input
                                                                                            {...register("mobile_phone")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Home"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['PHONE - HOME']}
                                                                                        />
                                                                                    </div> */}
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <label>Mobile Phone</label>
                                                                                        <input
                                                                                            {...register("mobile_phone")}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Cellular"
                                                                                            disabled={true}
                                                                                            value={activeuser?.['PHONE - HOME']}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <div className="form-group mb-3 col-md-4">
                                                                                <label>Property Size</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Property Size"
                                                                                    disabled={cardtitle === "View Application" ? true : false}
                                                                                    value={activeuser?.['PROPERTY SIZE']}
                                                                                />
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="form-group mb-3 col-md-6">
                                                                                    <label>Do you live in an unincorporated area in Lowndes County?</label>
                                                                                    <div className="btn-group makebtnleft pt-1 col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox1"
                                                                                                //onClick={() => checkboxFun("yes")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['UNICORP'] === "YES" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox1"
                                                                                            >Yes</label>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="btn-group pt-1 col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox2"
                                                                                                //onClick={() => checkboxFun("no")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['UNICORP'] === "NO" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox2"
                                                                                            >No</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-6">
                                                                                    <label>Type of residence?</label>
                                                                                    <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox1"
                                                                                                //onClick={() => checkboxFun("House")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "House" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox1"
                                                                                            >House</label>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="btn-group pt-1 col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox2"
                                                                                                //onClick={() => checkboxFun("Mobile Home")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['TYPE OF RESIDENCE'] === "Mobile home" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox2"
                                                                                            >Mobile Home</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="form-group mb-3 col-md-6">
                                                                                    <label>Water Source</label>
                                                                                    <div className="btn-group pt-1 makebtnleft col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox1"
                                                                                                //onClick={() => checkboxFun("Public")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['WATER SOURCE'] === "Public" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox1"
                                                                                            >Public</label>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="btn-group pt-1 col-md-3">
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="form-check-input"
                                                                                                id="checkbox2"
                                                                                                //onClick={() => checkboxFun("Private")}
                                                                                                disabled={cardtitle === "View Application" ? true : false}
                                                                                                checked={activeuser?.['WATER SOURCE'] === "Private" ? true : false}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label"
                                                                                                htmlFor="checkbox2"
                                                                                            >Private (Well)</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label># of Occupants  </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="# of Occupants  "
                                                                                        disabled={cardtitle === "View Application" ? true : false}
                                                                                        value={activeuser?.['OCCUPANTS']}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group mb-3 col-md-3">
                                                                                    <label># of Bedrooms </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="# of Bedrooms "
                                                                                        disabled={cardtitle === "View Application" ? true : false}
                                                                                        value={activeuser?.['BEDROOMS']}
                                                                                    />
                                                                                </div>
                                                                            </div> */}
                                                                                </div>
                                                                                {/* <div className="row">
                                                                            <div className="form-group mb-3 col-md-12">
                                                                                <label>What is your current method of sewage disposal?</label>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox1"
                                                                                            //onClick={() => checkboxFun("yes")}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                            checked={activeuser?.['SEWAGE DISPOSAL'] === "1 option" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox1"
                                                                                        >Straight pipe or septic system failure affecting a neighboring property.  </label>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox2"
                                                                                            //onClick={() => checkboxFun("no")}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                            checked={activeuser?.['SEWAGE DISPOSAL'] === "2 option" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox2"
                                                                                        >Straight pipe or septic system failure impacting a body of water (creek, drainage area, etc.)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox2"
                                                                                            //onClick={() => checkboxFun("no")}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                            checked={activeuser?.['SEWAGE DISPOSAL'] === "3 option" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox2"
                                                                                        >Straight pipe or septic system failure confined to my property or inside of home.</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="form-group mb-3 col-md-12">
                                                                                <label>Documents Needed</label>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox1"
                                                                                            onClick={() => setfileUpload1checkbox(!fileUpload1checkbox)}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox1"
                                                                                        >Copy of Driver’s License/Non-Driver ID</label>
                                                                                    </div>
                                                                                    {fileUpload1checkbox &&
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input type="file" disabled={cardtitle === "View Application" ? true : false} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox2"
                                                                                            onClick={() => setfileUpload2checkbox(!fileUpload2checkbox)}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox2"
                                                                                        >Proof of Land Ownership</label>
                                                                                    </div>
                                                                                    {fileUpload2checkbox &&
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input type="file" disabled={cardtitle === "View Application" ? true : false} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox2"
                                                                                            onClick={() => setfileUpload3checkbox(!fileUpload3checkbox)}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox2"
                                                                                        >Copy of Water Usage </label>
                                                                                    </div>
                                                                                    {fileUpload3checkbox &&
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input type="file" disabled={cardtitle === "View Application" ? true : false} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="btn-group pt-1 col-md-12">
                                                                                    <div className="form-check custom-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="checkbox2"
                                                                                            onClick={() => setfileUpload4checkbox(!fileUpload4checkbox)}
                                                                                            disabled={cardtitle === "View Application" ? true : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="checkbox2"
                                                                                        >Proof of Income (example: Tax Return/SSI)</label>
                                                                                    </div>
                                                                                    {fileUpload4checkbox &&
                                                                                        <div className="form-check custom-checkbox">
                                                                                            <input type="file" disabled={cardtitle === "View Application" ? true : false} />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                                <div className="row">
                                                                                    <div className="form-group mb-3 col-md-1">
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-2">
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-2">
                                                                                        <button type="button" className="btn btn-secondary" onClick={(e) => onSubmit('Approved')}>
                                                                                            Approve
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-2">
                                                                                        <button type="button" className="btn btn-danger" onClick={(e) => onSubmit('Rejected')}>
                                                                                            Reject
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="form-group mb-3 col-md-2">
                                                                                        <button type="button" className="btn btn-warning" onClick={(e) => cardbtn(e)}>
                                                                                            Back
                                                                                        </button>
                                                                                    </div>
                                                                                    {/* <div className="form-group mb-3 col-md-2">
                                                                                <button type="submit" className="btn btn-success" onClick={Print}>
                                                                                Print
                                                                                </button>
                                                                            </div> */}
                                                                                    <div className="form-group mb-3 col-md-1"></div>
                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <div className="basic-form">
                                                                                <div className="row">
                                                                                    <div className="form-group mb-3 col-md-4">
                                                                                        <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <Table responsive className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <strong>S NO.</strong>
                                                                                        </th>
                                                                                        <th>
                                                                                            <strong>MEMBER NAME</strong>
                                                                                        </th>
                                                                                        <th>
                                                                                            <strong>FIRST NAME</strong>
                                                                                        </th>
                                                                                        <th>
                                                                                            <strong>LAST NAME</strong>
                                                                                        </th>
                                                                                        <th>
                                                                                            <strong>FLAG</strong>
                                                                                        </th>
                                                                                        <th>
                                                                                            <strong>Actions</strong>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {FilteredData().map((item, i) => {
                                                                                        console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                                                                        return [
                                                                                            <tr key={i}>
                                                                                                <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                                                                <td>{item.member_name}</td>
                                                                                                <td>{item.first_name}</td>
                                                                                                <td>{item.last_name}</td>
                                                                                                <td>
                                                                                                    <i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i>
                                                                                                    {item.STATUS === "rejected" && <i className={"fa fa-flag fa-2x" + " text-red"}></i>}
                                                                                                    {item.STATUS === "approved" && <i className={"fa fa-flag fa-2x" + " text-blue"}></i>}
                                                                                                    {item.STATUS === "pending" && <i className={"fa fa-flag fa-2x" + " text-yellow"}></i>}
                                                                                                </td>
                                                                                                <td><div className="d-flex">
                                                                                                    <Link
                                                                                                        onClick={(e) => viewuser(e, item)}
                                                                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                                                                    >
                                                                                                        <i className="fa fa-eye"></i>
                                                                                                    </Link>
                                                                                                    <Link
                                                                                                        onClick={(e) => edituser(e, item)}
                                                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                                    >
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </Link>
                                                                                                    <Link
                                                                                                        onClick={(e) => deleteuser(e, item, 'Pending')}
                                                                                                        className="btn btn-danger shadow btn-xs sharp"
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </Link>
                                                                                                </div></td>
                                                                                            </tr>
                                                                                        ];
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                            <br />
                                                                            {FilteredData().length > 0 &&
                                                                                <Pagination>
                                                                                    <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                                                                    <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                                                                        pageArray && pageArray.length &&
                                                                                        pageArray.map(
                                                                                            (item) => (
                                                                                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                                                                    active={currentPage == item}>{item}</Pagination.Item>
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                                                                    <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                                                                </Pagination>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="Rejected">
                                                                <div className="row">
                                                                    <>
                                                                        <div className="basic-form">
                                                                            <div className="row">
                                                                                <div className="form-group mb-3 col-md-4">
                                                                                    <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <Table responsive className="table table-striped">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <strong>S NO.</strong>
                                                                                    </th>
                                                                                    <th>
                                                                                        <strong>MEMBER NAME</strong>
                                                                                    </th>
                                                                                    <th>
                                                                                        <strong>FIRST NAME</strong>
                                                                                    </th>
                                                                                    <th>
                                                                                        <strong>LAST NAME</strong>
                                                                                    </th>
                                                                                    <th>
                                                                                        <strong>FLAG</strong>
                                                                                    </th>
                                                                                    <th>
                                                                                        Actions
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {FilteredData().map((item, i) => {
                                                                                    console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize));
                                                                                    return [
                                                                                        <tr key={i}>
                                                                                            <td>{(parseInt(currentPage) * parseInt(pageSize) - parseInt(pageSize)) + 1 + i}</td>
                                                                                            <td>{item.member_name}</td>
                                                                                            <td>{item.first_name}</td>
                                                                                            <td>{item.last_name}</td>
                                                                                            <td>
                                                                                                <i class="fa fa-flag fa-2x" style={{ color: item.flag }}></i>
                                                                                                {item.STATUS === "rejected" && <i className={"fa fa-flag fa-2x" + " text-red"}></i>}
                                                                                                {item.STATUS === "approved" && <i className={"fa fa-flag fa-2x" + " text-blue"}></i>}
                                                                                                {item.STATUS === "pending" && <i className={"fa fa-flag fa-2x" + " text-yellow"}></i>}
                                                                                            </td>
                                                                                            <td><div className="d-flex">
                                                                                                <Link
                                                                                                    onClick={(e) => deleteuser(e, item, 'Rejected')}
                                                                                                    className="btn btn-danger shadow btn-xs sharp"
                                                                                                >
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                </Link>
                                                                                            </div></td>
                                                                                        </tr>
                                                                                    ];
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                        <br />
                                                                        {FilteredData().length > 0 &&
                                                                            <Pagination>
                                                                                <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                                                                                <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                                                                                    pageArray && pageArray.length &&
                                                                                    pageArray.map(
                                                                                        (item) => (
                                                                                            <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                                                                                active={currentPage == item}>{item}</Pagination.Item>
                                                                                        )
                                                                                    )
                                                                                }
                                                                                <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                                                                                <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                                                                            </Pagination>
                                                                        }
                                                                    </>
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="soiltest" >
                                                    <Soiltest activeTab={activeTab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="engineeringdesign" >
                                                    <EngineeringDesign activeTab={activeTab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="installation" >
                                                    <Installation activeTab={activeTab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="electricalplumbing" >
                                                    <ElectricalPlumbing activeTab={activeTab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="summary">
                                                    <Summary activeTab={activeTab} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="print">
                                                    {/* <HeaderPrint /> */}
                                                    <InvoicePrint PrintValues={PrintValues}></InvoicePrint>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </Fragment>
    )
}
