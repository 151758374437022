import React,{useState,useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Images
import pattern6 from './../../../images/pattern/pattern6.png';
import circle from './../../../images/pattern/circle.png';
//Components
import { ThemeContext } from "../../../context/ThemeContext";
import DropdownBlog from '../Dompet/DropdownBlog';
import PreviousTab from '../Dompet/Home/PreviousTab';
import InvoiceCard from '../Dompet/Home/InvoiceCard';
import SpendingsBlog from '../Dompet/Home/SpendingsBlog';
import QuickTransferBlog from '../Dompet/Home/QuickTransferBlog';
import CardBlog from '../Dompet/Home/CardBlog';
import MapApp from '../../custom/maps/Map';
import TraditionalCard from '../Dompet/Home/TraditionalCard';
import EngineeredBlog from '../Dompet/Home/EngineeredBlog';
import AdvanceEngineeredBlog from '../Dompet/Home/AdvanceEngineeredBlog';
import Newcomp from '../Dompet/Home/Newcomp';

const PolarChart = loadable(() =>
	pMinDelay(import("../Dompet/Home/PolarChart"), 1000)
);
const ActivityApexBarGraph = loadable(() =>
	pMinDelay(import("../Dompet/Home/ActivityApexBarGraph"), 1000)
);
const TransactionApexBar = loadable(() =>
	pMinDelay(import("../Dompet/Home/TransactionApexBar"), 1000)
);
const backgroundColorUNZ1234={
	backgroundColor:'#e13de8'
}
const backgroundColorUNZ3453={
	backgroundColor:'#1f39b7'
}
const backgroundColorUNZ2344={
	backgroundColor:'#08ba27'
}
const backgroundColorUNZ3535={
	backgroundColor:'#ab826e'
}
const backgroundColorUNZ7643={
	backgroundColor:'#513fb9'
}
const backgroundColorUNZ2674={
	backgroundColor:'#f0ef4f'
}
const backgroundColorUNZ5434={
	backgroundColor:'#12a76f'
}
const backgroundColorUNZ5323={
	backgroundColor:'#eb655d'
}

const Home = () => {
	const [checked, setChecked] = useState(true);
	const { changeBackground, background  } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
		
	return(
		<>
			 <div style={{ backgroundColor: '#fff',borderRadius:'1.75rem' }} className="row invoice-card-row ">
				<InvoiceCard />
			</div> 
			{/* <div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center">
							<InvoiceCard />
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center">
								<MapApp />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center">
								{/* <div className="col-xl-6">
									<div className="card-bx bg-blue">
										<img className="pattern-img" src={pattern6} alt="" />
										<div className="card-info text-white">
											<img src={circle} className="mb-4" alt="" />
											<h2 className="text-white card-balance">$18,571.25</h2>
											<p className="fs-16">Weekly Revenue</p>
											<span>+0,8% than last week</span>
										</div>
										<Link to={"#"}className="change-btn" id="change-btn" ><i className="fa fa-caret-up up-ico"></i>Change<span className="reload-icon"><i className="fa fa-refresh reload active"></i></span></Link>
									</div>
								</div> */}
								<div className="col-xl-12">
									<div className="row  mt-xl-0 mt-4">
										<div className="col-md-6">
											<h4 className="card-title">Systems by Non-Sewered Service Zone</h4>
											<span></span>
											<ul className="card-list mt-4">
												<li><span style={backgroundColorUNZ1234} className="circle"></span>UNZ 1234<span>21%</span></li>
												<li><span style={backgroundColorUNZ3453} className=" circle"></span>UNZ 3453<span>46%</span></li>
												<li><span style={backgroundColorUNZ2344} className=" circle"></span>UNZ 2344<span>57%</span></li>
												<li><span style={backgroundColorUNZ3535} className=" circle"></span>UNZ 3535<span>33%</span></li>
												<li><span style={backgroundColorUNZ7643} className=" circle"></span>UNZ 7643<span>74%</span></li>
												<li><span style={backgroundColorUNZ2674} className=" circle"></span>UNZ 2674<span>53%</span></li>
												<li><span style={backgroundColorUNZ5434} className=" circle"></span>UNZ 5434<span>63%</span></li>
												<li><span style={backgroundColorUNZ5323} className=" circle"></span>UNZ 5323<span>46%</span></li>
											</ul>
										</div>
										<div className="col-md-6">
											<PolarChart />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="col-xl-3 col-xxl-5">
					<div className="card">
						<div className="card-header pb-0 border-0">
							<div>
								<h4 className="card-title mb-2">Activity</h4>
								<h2 className="mb-0">$78120</h2>
							</div>
							<ul className="card-list">
								<li className="justify-content-end">Income<span className="bg-success circle me-0 ms-2"></span></li>
								<li className="justify-content-end">Outcome<span className="bg-danger circle me-0 ms-2"></span></li>
							</ul>
						</div>
						<div className="card-body pb-0 pt-3">
							<div id="chartBar" className="bar-chart">
								<ActivityApexBarGraph />
							</div>
						</div>
					</div>
				</div> */}
				{/* <div className="col-xl-3 col-xxl-7">
					<QuickTransferBlog />
				</div> */}
				 <div className="col-xl-12 col-xxl-12">
					<SpendingsBlog />
				</div> 
				<div className="col-xl-12 col-xxl-12">
					<EngineeredBlog />
				</div> 
				<div className="col-xl-12 col-xxl-12">
					<AdvanceEngineeredBlog />
				</div> 
				{/* <div className="row invoice-card-row">
					<TraditionalCard/>
				</div> */}
				{/* <div className="col-xl-6 col-xxl-12">
					<div className="card">
						<div className="card-header d-flex flex-wrap border-0 pb-0">
							<div className="me-auto mb-sm-0 mb-3">
								<h4 className="card-title mb-2">Transaction Overview</h4>
								<span className="fs-12"></span>
							</div>
							<Link to={"#"} className="btn btn-rounded btn-md btn-primary mr-3 me-3"><i className="las la-download scale5 me-3"></i>		Download Report
							</Link>
							<DropdownBlog />
						</div>
						<div className="card-body pb-2">
							<div className="d-sm-flex d-block">
								<div className="form-check toggle-switch text-end form-switch me-4">
								  <input 
										className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={checked}
										onChange={() => setChecked(!checked)} />
								  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Number</label>
								</div>
								<div className="form-check toggle-switch text-end form-switch me-auto">
								  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" />
								  <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Analytics</label>
								</div>
								<ul className="card-list d-flex mt-sm-0 mt-3">
									<li className="me-3"><span className="bg-success circle"></span>Income</li>
									<li><span className="bg-danger circle"></span>Outcome</li>
								</ul>
							</div>
							<div id="chartBar2" className="bar-chart">
								<TransactionApexBar />
							</div>
						</div>
					</div>
				</div>
				<PreviousTab  />  */}
				<div style={{ backgroundColor: '#fff',borderRadius:'1.75rem' }} >
					<TraditionalCard/>
				</div>
				<br></br>
				<div>
					<CardBlog />
				</div>
				
			</div>			
		</>
	)
}
export default Home;