import React, { Fragment } from "react";
import { Row, Col, Card,  Tab, Nav } from "react-bootstrap";
import PageTItle from "../../layouts/PageTitle";
import ComplaintsTable from "../../components/table/ComplaintsTable"
import  data from "../../components/table/tableData.js";

function Warehouse() {
    return (
        <Fragment>
            <PageTItle activeMenu="Warehouse" motherMenu="Warehouse" pageContent="Warehouse" />
            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Warehouse</h4>
                    </div>
                    <div className="card-body">
                        <Tab.Container defaultActiveKey="addproduct">
                            <Nav as="ul" className="nav-pills mb-4 light">
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="addproduct">
                                        Add Product
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="addstock">
                                        Add Stock
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="orders">
                                        Orders
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="">
                                <Tab.Pane eventKey="addproduct">
                                    <div className="col-xl-12 col-lg-12">
                                        <h4 className="card-title">Add Product</h4>
                                        <div className="basic-form">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>Product Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Product Name"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>Unit Cost</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Unit Cost"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-3">
                                                    </div>
                                                        <div className="form-group mb-3 col-md-3">
                                                            <button type="submit" className="btn btn-primary">
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-3">
                                                            <button type="submit" className="btn btn-warning">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    <div className="form-group mb-3 col-md-3"></div>
                                                </div>
                                            </form>
                                        </div>
                                        <h4 className="card-title">List of Products</h4>
                                        <ComplaintsTable tablename="List of Products" tabledata={data.listoforderTable} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="addstock">
                                <div className="col-xl-12 col-lg-12">
                                        <h4 className="card-title">Add Stock</h4>
                                        <div className="basic-form">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>Product Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Product Name"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label>Enter Quantity</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Quantity"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-3">
                                                    </div>
                                                        <div className="form-group mb-3 col-md-3">
                                                            <button type="submit" className="btn btn-primary">
                                                                Save
                                                            </button>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-3">
                                                            <button type="submit" className="btn btn-warning">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    <div className="form-group mb-3 col-md-3"></div>
                                                </div>
                                            </form>
                                        </div>
                                        <h4 className="card-title">List of Stocks</h4>
                                        <ComplaintsTable tablename="List of Available Stocks" tabledata={data.listoforderTable} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="orders">
                                    <div className="col-xl-12 col-lg-12">
                                        <Tab.Container defaultActiveKey="pendingorders">
                                            <Nav as="ul" className="nav-pills mb-4 light">
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="pendingorders">
                                                        Pending Orders
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link eventKey="deliveredorders">
                                                        Delivered Orders
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className="">
                                                <Tab.Pane eventKey="pendingorders">
                                                    <ComplaintsTable tablename="List of Available Stocks" tabledata={data.listoforderTable} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="deliveredorders">
                                                    <ComplaintsTable tablename="List of Available Stocks" tabledata={data.listoforderTable} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Warehouse
