import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//import BBUWP from "../../../images/BBUWP.png";
import BBUWP from "../../../images/iwashwelllogowhite.png";
export default function SecondPagePrint() {

    useEffect(() => {


    }, [])
    return (
        <>
            <div>
                <div className="row">
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif' }}>
                            <strong>STATE OF ALABAMA</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <br />
                            ______________________{' '}
                            <span style={{ fontWeight: 'strong' }}>COUNTY</span>
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            I, a Notary Public, hereby certify that{' '}
                            <span style={{ textDecoration: 'underline' }}>_______________________________________</span> whose
                            name is signed to the foregoing instrument or conveyance, and who is known to me, acknowledged before me
                            on this day that, being informed of the contents of the conveyance, he/she executed the same
                            voluntarily on the day the same bears date.
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Given under my hand this _____________ day of _____________, A. D. 20____.
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            {' '}
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Notary Public:
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <br />
                            Print Name _______________________________
                        </span>
                    </p>
                    <p>
                        <span style={{ color: '#000000', fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <br />
                            My Commission Expires: ______________________
                        </span>
                    </p>
                    <br />
                    <br />
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Date Received: __________________ Local Health Department Permit #: _________________
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Date Approved for Use Installed: __________________
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Soil Professional&rsquo; Name: _________________________{' '}
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Application Approved:{' '}
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAASklEQVR4nGMxCp/MwMjAxEAy+P//PwsDAyMjw3/S9QJ1M7IwAnX/Zzi9IodUvSbhU1nIsRMGRjWPah7VjEszI4NR6FRSdTIxMQAAmBcLh4T2exsAAAAASUVORK5CYII="
                                width="18"
                                height="16"
                                name="Picture 92"
                                align="bottom"
                                border="0"
                            />{' '}
                            Yes{' '}
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAASklEQVR4nGMxCp/MwMjAxEAy+P//PwsDAyMjw3/S9QJ1M7IwAnX/Zzi9IodUvSbhU1nIsRMGRjWPah7VjEszI4NR6FRSdTIxMQAAmBcLh4T2exsAAAAASUVORK5CYII="
                                width="18"
                                height="16"
                                name="Picture 5"
                                align="bottom"
                                border="0"
                            />{' '}
                            No (see comments)
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            Date Installed: _______________ Installer&rsquo;s Name: ___________________
                        </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>Documentation Received:</span>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            {' '}
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAASklEQVR4nGMxCp/MwMjAxEAy+P//PwsDAyMjw3/S9QJ1M7IwAnX/Zzi9IodUvSbhU1nIsRMGRjWPah7VjEszI4NR6FRSdTIxMQAAmBcLh4T2exsAAAAASUVORK5CYII="
                                width="18"
                                height="16"
                                name="Picture 6"
                                align="bottom"
                                border="0"
                            />{' '}
                            CEP-5 Installer Certification{' '}
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAASklEQVR4nGMxCp/MwMjAxEAy+P//PwsDAyMjw3/S9QJ1M7IwAnX/Zzi9IodUvSbhU1nIsRMGRjWPah7VjEszI4NR6FRSdTIxMQAAmBcLh4T2exsAAAAASUVORK5CYII="
                                width="18"
                                height="16"
                                name="Picture 7"
                                align="bottom"
                                border="0"
                            />{' '}
                            CEP-6 Engineer Certification (if applicable){' '}
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAASklEQVR4nGMxCp/MwMjAxEAy+P//PwsDAyMjw3/S9QJ1M7IwAnX/Zzi9IodUvSbhU1nIsRMGRjWPah7VjEszI4NR6FRSdTIxMQAAmBcLh4T2exsAAAAASUVORK5CYII="
                                width="18"
                                height="16"
                                name="Picture 8"
                                align="bottom"
                                border="0"
                            />{' '}
                            Covenant (if applicable)
                        </span>
                    </p>
                    <p>
                        <br />
                        <br />
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <strong>COMMENTS:</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <strong>______________________________________________________________________________________________</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <strong>______________________________________________________________________________________________</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <strong>______________________________________________________________________________________________</strong>
                        </span>
                    </p>
                    <p>
                        <span style={{ fontFamily: 'Arial, serif', fontSize: 'small' }}>
                            <strong>______________________________________________________________________________________________</strong>
                        </span>
                    </p>
                </div>
            </div >
        </>
    )
}
